import copy from "copy-to-clipboard";
import { useCallback } from "react";
import { useSnackbar } from ".";
import { useMainContext } from "../reducer/searchReducer";
import { IReducerState, IReducerActions } from "../utils/interface";

export const useCopyUtils = () => {
    const { displaySnackbar } = useSnackbar();

  // get reducer from context
  const {
    state,
    dispatch,
  }: { state: IReducerState; dispatch: React.Dispatch<IReducerActions> } =
    useMainContext();
    
  const handleCopyTable = useCallback(() => {
    if (!state.search?.tableData?.length)
      displaySnackbar("error", "No data in table");
    const table = document.createElement("table");

    state.search.tableData!.map((row) => {
      const tableRow = document.createElement("tr");
      const nameCell = document.createElement("td");
      nameCell.innerText = row.name;
      const stockCodeCell = document.createElement("td");
      stockCodeCell.innerText = row.stockCode;
      const descCodeCell = document.createElement("td");
      descCodeCell.innerText = row.description || "";
      tableRow.append(nameCell);
      tableRow.append(stockCodeCell);
      tableRow.append(descCodeCell);
      table.append(tableRow);
    });

    copy(table.outerHTML, { format: "text/html" });
  }, [displaySnackbar, state.search.tableData]);

  const handleCopy = useCallback(() => {
    if (!state.search?.tableData?.length)
      displaySnackbar("error", "No data in table");

    const data = state.search.tableData!.map(
      (row) => `${row.name}\t${row.stockCode}\t${row.description}`
    );

    copy(data.join("\n"));
  }, [displaySnackbar, state.search.tableData]);

  return { handleCopyTable, handleCopy };
}