import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useTheme } from "@mui/material/styles";
import Container from "@mui/material/Container";
import {
  Button,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Theme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AvistaAppBar } from "@avista/avista-app-bar";
import { AvistaSnackbar } from "@avista/avista-snackbar";
import { NavDrawer } from "./NavDrawer";
import { navItems } from "./navItems";
import { SERVER } from "../utils/const";
import { AvistaAvatar } from "@avista/avista-avatar";
import {
  IReducerActions,
  IReducerState,
  ReducerActionType,
} from "../utils/interface";
import { useMainContext } from "../reducer/searchReducer";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    maxWidth: "100vw",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 99,
  },
  input: {
    display: "flex",
    padding: 0,
    // height: "60px",
    "& .MuiInput-input": {
      display: "flex",
      padding: 0,
    },
  },
}));

const Layout = (props: React.PropsWithChildren) => {
  const [isOpenDrawer, setIsOpenDrawer] = React.useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();

  // get reducer from context
  const {
    state,
    dispatch,
  }: { state: IReducerState; dispatch: React.Dispatch<IReducerActions> } =
    useMainContext();

  const handleSnackbarClose = React.useCallback(() => {
    if (state.snackbar.redirectUrl) {
      navigate(state.snackbar.redirectUrl);
    }
    dispatch({
      type: ReducerActionType.hideSnackbar,
    });
  }, [navigate, dispatch, state]);

  const handleConfirmationDialogClose = React.useCallback(() => {
    dispatch({
      type: ReducerActionType.hideConfirmationDialog,
    });
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AvistaAppBar
        children={{
          mainChildrenItems: navItems,
          rightChildren: (
            <>
              <AvistaAvatar
                loginServerUrl={SERVER.LOGIN_HOST!}
                myProfileUrl={SERVER.ADMIN_PROFILE_URL!}
              />
            </>
          ),
        }}
        options={{
          appBarStyle: classes.appBar,
        }}
      />
      <NavDrawer
        open={isOpenDrawer}
        onClose={() => {
          setIsOpenDrawer(false);
        }}
        style={{ zIndex: theme.zIndex.drawer + 98 }}
        setIsOpenDrawer={setIsOpenDrawer}
      />
      <main>
        <div className={classes.appBarSpacer} />
        <Container
          maxWidth="xl"
          className={classes.container}
          children={props.children as NonNullable<React.ReactNode>}
        ></Container>

        <AvistaSnackbar
          isOpen={state.snackbar.isOpen!}
          text={state.snackbar.text!}
          type={state.snackbar.type!}
          handleClose={handleSnackbarClose}
        />

        <Dialog
          open={state.ConfirmationDialog.isOpen!}
          onClose={handleConfirmationDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {state.ConfirmationDialog.title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {state.ConfirmationDialog.description}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={state.ConfirmationDialog.button1Callback}>
              {state.ConfirmationDialog.button1Text}
            </Button>
            <Button onClick={state.ConfirmationDialog.button2Callback} autoFocus>
              {state.ConfirmationDialog.button2Text}
            </Button>
          </DialogActions>
        </Dialog>
      </main>
    </div>
  );
};

export { useStyles, Layout };
