import { SearchRelatedCompany, SearchTableData } from "../interface";

const getStockCodeFromStockExchangeTicker = (
  stockExchange: string,
  ticker: string
) => (stockExchange ? stockExchange + ":" + ticker : ticker);

const companyInfosToSearchTableData = (
  companyInfos: SearchRelatedCompany[]
): SearchTableData[] =>
  companyInfos.map((item) => ({
    ...item,
    stockCode: getStockCodeFromStockExchangeTicker(
      item.stockExchange,
      item.ticker
    ),
  }));

export { getStockCodeFromStockExchangeTicker, companyInfosToSearchTableData };
