import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useApi } from "../hooks";
import { AvistaAsyncSelect } from "@avista/avista-select";
import { debounce } from "@mui/material";
import { CompanyOptions, SearchRelatedCompany } from "../utils/interface";
import { useState } from "react";
import { useStyles } from "../layout";

export default function AddCompaniesDialog({
  handleAddCompanies,
}: {
  handleAddCompanies: (companies: SearchRelatedCompany[]) => void;
}) {
  const classes = useStyles()

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = () => {
    handleAddCompanies(companies);
    setOpen(false);
  };
  
  const handleSelectChanged = (value, action) => {
    // Send search request
    const options = value as CompanyOptions[];
    if (options.length)
      setCompanies(
        options.map((option) => ({
          id: option.data.id,
          name: option.data.name,
          stockExchange: option.data.stockExchange,
          ticker: option.data.ticker,
          description: option.data.description,
        }))
      );
  };
  const api = useApi();

  const [companies, setCompanies] = useState<SearchRelatedCompany[]>([]);

  return (
    <div>
      <IconButton aria-label="delete" size="large" onClick={handleClickOpen}>
        <AddCircleIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth={true}>
        <DialogTitle>Add Company</DialogTitle>
        <DialogContent style={{ height: 400 }}>
          <AvistaAsyncSelect
            label="Companies"
            TextFieldProps={{ fullWidth: true, className: classes.input, multiline: true }}
            variant="new"
            helperText="Please type at least 2 words to start searching"
            loadOptions={debounce(api.getCompanies, 1000)}
            isMulti
            onChange={handleSelectChanged}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
