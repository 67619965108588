import { useApolloClient } from "@apollo/client/react/hooks";
import { useCallback } from "react";
import {
  Company,
  GetCompaniesDropdownOptionsDocument,
  GetCompaniesDropdownOptionsQuery,
  GetCompaniesDropdownOptionsQueryVariables,
  useCreateFeedbackDataMutation,
  useGetCompaniesDropdownOptionsLazyQuery,
  useGetOpenAiModelsLazyQuery,
  useSearchRelatedCompaniesLazyQuery,
  useSearchRelatedCompaniesQuery,
} from "../generated/graphql";
import { AvistaOptionTypeBase } from "@avista/avista-select";
import { GroupTypeBase } from "react-select";
import {
  IReducerActions,
  IReducerState,
  ISnackbarType,
  ReducerActionType,
} from "../utils/interface";
import { useMainContext } from "../reducer/searchReducer";

export const useApi = () => {
  const client = useApolloClient();

  const convertToCompanySelectOption = useCallback((company: Company) => {
    return {
      value: company.name,
      label: `${company.name} (${company.stockExchange}:${company.ticker})`,
      data: company,
    };
  }, []);

  const [getCompaniesDropdownOptions] =
    useGetCompaniesDropdownOptionsLazyQuery();

  const getCompaniesForAsyncSelect = useCallback(
    async (
      input: string,
      callback: (
        options: readonly (
          | AvistaOptionTypeBase<string>
          | GroupTypeBase<AvistaOptionTypeBase<string>>
        )[]
      ) => void
    ) => {
      if (input.length < 2) {
        callback([]);
        return;
      }
      let options: any = [];
      try {
        await getCompaniesDropdownOptions({
          variables: {
            keyword: input,
          },
          onCompleted(data) {
            if (data?.getCompaniesDropdownOptions?.length) {
              options = data.getCompaniesDropdownOptions.map((item) =>
                convertToCompanySelectOption(item as Company)
              );
            }
            callback(options);
          },
        });
      } catch (error) {
        console.log("error", error);
      }
      return options;
    },
    [convertToCompanySelectOption, getCompaniesDropdownOptions]
  );

  const [
    searchRelatedCompanies,
    {
      data: searchRelatedCompaniesData,
      loading: searchRelatedCompaniesLoading,
    },
  ] = useSearchRelatedCompaniesLazyQuery();

  const [
    createFeedbackData,
    { data: createFeedbackDataResponse, loading: createFeedbackDataLoading },
  ] = useCreateFeedbackDataMutation();

  const [getOpenAiModels, { data: getOpenAiModelsData }] =
    useGetOpenAiModelsLazyQuery();

  return {
    getCompanies: getCompaniesForAsyncSelect,
    searchRelatedCompanies,
    searchRelatedCompaniesData,
    searchRelatedCompaniesLoading,
    useSearchRelatedCompaniesQuery,
    createFeedbackData,
    createFeedbackDataResponse,
    createFeedbackDataLoading,
    getOpenAiModels,
    getOpenAiModelsData,
  };
};

export const useSnackbar = () => {
  // get reducer from context
  const {
    state,
    dispatch,
  }: { state: IReducerState; dispatch: React.Dispatch<IReducerActions> } =
    useMainContext();

  const displaySnackbar = useCallback(
    (snackbarType: ISnackbarType, message: string) => {
      dispatch({
        type: ReducerActionType.showSnackbar,
        payload: {
          snackbar: {
            text: message,
            type: snackbarType,
          },
        },
      });
    },
    [dispatch]
  );

  return { displaySnackbar };
};
