import { Paper } from "@mui/material";
import { IReducerActions, IReducerState } from "../../utils/interface";
import { useMainContext } from "../../reducer/searchReducer";
import { Container } from "@mui/system";
import { SearchPanel } from "./searchPanel";
import { SearchResult } from "./searchResult";

export const Search = () => {
  const {
    state,
  }: { state: IReducerState; dispatch: React.Dispatch<IReducerActions> } =
    useMainContext();

  return (
    <Container>
      <h3>Search Company</h3>
      <Paper style={{ padding: "20px", minWidth: "80vw" }}>
        {!state.search.aiSearchData?.length && <SearchPanel></SearchPanel>}
        {!!state.search.aiSearchData?.length && <SearchResult></SearchResult>}
      </Paper>
    </Container>
  );
};
