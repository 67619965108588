import React from "react";
import { initialState, reducer } from "../reducer/searchReducer";
import { IContextProps } from "../utils/interface";

export const MainContext = React.createContext({} as IContextProps);

export const MainContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, { ...initialState });
  const value = { state, dispatch };
  return <MainContext.Provider value={value}>{children}</MainContext.Provider>;
};
