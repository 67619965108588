
interface NavItem {
  name: string;
  url: string;
}

const navItems: NavItem[] = [
  { name: "AICCS Search", url: "/search" },
];

export { navItems };
