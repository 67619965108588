import { useCallback } from "react";
import {
  ReducerActionType,
  IReducerActions,
  IReducerState,
} from "../utils/interface";
import { useMainContext } from "../reducer/searchReducer";
import { useApi, useSnackbar } from ".";
import { useCopyUtils } from "./copyUtils";

export const useSearchResultHook = () => {
  const api = useApi();
  // get reducer from context
  const {
    state,
    dispatch,
  }: { state: IReducerState; dispatch: React.Dispatch<IReducerActions> } =
    useMainContext();

  const { displaySnackbar } = useSnackbar();

  const { handleCopyTable, handleCopy } = useCopyUtils();

  const submitFeedback = useCallback(async () => {
    if (!state.search?.searchCompany) {
      displaySnackbar(
        "warning",
        "Please choose company and have at least one comparable company in table."
      );
      return;
    }

    const targetCompanyName = state.search.searchCompany.name;
    const targetCompanyDesc = state.search.searchCompany.description;

    const relatedCompanies: string[] = [];
    const relatedCompaniesStockCode: string[] = [];

    if (!state.search?.tableData?.length) {
      displaySnackbar(
        "warning",
        "Please choose company and have at least one comparable company in table."
      );
      return;
    }

    for (let i = 0; i < state.search.tableData.length; i++) {
      const item = state.search.tableData[i];
      if (!item.stockExchange || !item.ticker) {
        displaySnackbar(
          "warning",
          "Please remove invalid comparable company in table. All comparable companies should have stock code."
        );
        return;
      }
      relatedCompaniesStockCode.push(item.stockExchange + ":" + item.ticker);
      relatedCompanies.push(item.name);
    }

    const aiRelatedCompanies: string[] = [];
    const aiRelatedCompaniesStockCode: string[] = [];
    state.search?.aiSearchData?.forEach((item) => {
      aiRelatedCompaniesStockCode.push(
        item.stockExchange
          ? item.stockExchange + ":" + item.ticker
          : item.ticker
      );
      aiRelatedCompanies.push(item.name);
    });

    if (!targetCompanyName) {
      displaySnackbar(
        "warning",
        "Please input company name before submit the list for feedback"
      );
      return;
    }

    if (!targetCompanyDesc || !relatedCompanies?.length) {
      displaySnackbar(
        "warning",
        "Please choose company and have at least one comparable company in table."
      );

      return;
    }

    // Submit training data
    const result = await api.createFeedbackData({
      variables: {
        company: targetCompanyName,
        companyDescription: targetCompanyDesc,
        companyWebsite: state.search.searchCompany.website ?? "",
        aiRelatedCompanies,
        aiRelatedCompaniesStockCode,
        relatedCompanies,
        relatedCompaniesStockCode,
      },
    });

    if (result.data) displaySnackbar("success", "Submitted");
    else
      displaySnackbar(
        "error",
        result.errors?.length
          ? JSON.stringify(result.errors)
          : "Submission failed. Please contact IT."
      );
  }, [
    api,
    displaySnackbar,
    state.search?.aiSearchData,
    state.search.searchCompany,
    state.search.tableData,
  ]);

  const feedbackSubmitClicked = useCallback(() => {
    dispatch({
      type: ReducerActionType.showConfirmationDialog,
      payload: {
        ConfirmationDialog: {
          isOpen: true,
          title: "Submit list for feedback",
          description:
            "Are you sure you want to submit this list for feedback?",
          button1Text: "Submit",
          button2Text: "Cancel",
          button1Callback: () => {
            submitFeedback();
            dispatch({ type: ReducerActionType.hideConfirmationDialog });
          },
          button2Callback: () => {
            dispatch({ type: ReducerActionType.hideConfirmationDialog });
          },
        },
      },
    });
  }, [dispatch, submitFeedback]);

  const searchCompanyUpdated = useCallback(() => {
    // clear result table if description is empty
    dispatch({
      type: ReducerActionType.setAiSearchData,
      payload: {
        search: {
          aiSearchData: [],
        },
      },
    });
    dispatch({
      type: ReducerActionType.setSearchTableData,
      payload: {
        search: {
          tableData: [],
        },
      },
    });
  }, [dispatch]);

  return {
    feedbackSubmitClicked,
    searchCompanyUpdated,
    handleCopyTable,
    handleCopy,
  };
};
