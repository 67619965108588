import { Company } from "../../generated/graphql";

type SearchInfo = {
  description?: string;
  name?: string;
  website?: string;
  model?: string;
};
type SearchRelatedCompany = Pick<
  Company,
  "id" | "name" | "stockExchange" | "ticker" | "description"
>;

type SearchTableData = SearchRelatedCompany & {
  stockCode: string;
};

type CompanyOptions = {
  value: string;
  label: string;
  data: SearchRelatedCompany;
};

type ISnackbarType = "success" | "error" | "info" | "warning";

type IReducerActions = {
  type: ReducerActionType;
  payload?: Partial<IReducerState>;
};
interface IReducerState {
  snackbar: Partial<ISnackbar>;
  ConfirmationDialog: Partial<IConfirmationDialog>;
  search: Partial<ISearchReducerState>;
}

interface ISnackbar {
  text: string;
  isOpen: boolean;
  type: ISnackbarType;
  duration?: number;
  redirectUrl?: string;
}

interface IConfirmationDialog {
  title: string;
  description: string;
  isOpen: boolean;
  button1Text: string;
  button2Text: string;
  button1Callback: () => void;
  button2Callback: () => void;
}

interface ISearchReducerState {
  searchCompany?: SearchInfo;
  tableData?: SearchTableData[];
  aiSearchData?: SearchTableData[];
  tableLoading?: boolean;
}

enum ReducerActionType {
  showSnackbar = "showSnackbar",
  hideSnackbar = "hideSnackbar",
  showConfirmationDialog = "showConfirmationDialog",
  hideConfirmationDialog = "hideConfirmationDialog",
  setSearchCompany = "setSearchCompany",
  setSearchTableData = "setSearchTableData",
  setAiSearchData = "setAiSearchData",
  setTableLoading = "setTableLoading",
}

interface IContextProps {
  state: IReducerState;
  dispatch: React.Dispatch<IReducerActions>;
}
export { ReducerActionType };
export type {
  CompanyOptions,
  SearchRelatedCompany,
  SearchInfo,
  SearchTableData,
  ISnackbarType,
  ISnackbar,
  IReducerState,
  IReducerActions,
  IContextProps,
  ISearchReducerState,
};
