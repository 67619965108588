import * as React from "react";
import { useMainContext } from "../reducer/searchReducer";
import { DataGrid, GridColDef, GridToolbarContainer } from "@mui/x-data-grid";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import AddCompaniesDialog from "./AddCompaniesDialog";
import {
  IReducerActions,
  IReducerState,
  ReducerActionType,
  SearchRelatedCompany,
  SearchTableData,
} from "../utils/interface";
import { companyInfosToSearchTableData } from "../utils/helper";

import CloseIcon from "@mui/icons-material/Close";
import NotesIcon from "@mui/icons-material/Notes";

export default function SearchResultTable() {
  // get reducer from context
  const {
    state,
    dispatch,
  }: { state: IReducerState; dispatch: React.Dispatch<IReducerActions> } =
    useMainContext();

  const handleDeleteClick = (id: string) => () => {
    dispatch({
      type: ReducerActionType.setSearchTableData,
      payload: {
        search: {
          tableData: state?.search?.tableData?.filter((row) => row.id !== id),
        },
      },
    });
  };
  const DescriptionCell = (props: { description: string }) => {
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
      setOpen(!open);
    };
    return (
      <Box sx={{ display: "flex", position: "relative" }}>
        <IconButton onClick={handleClick}>
          <NotesIcon />
        </IconButton>
        <p
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {props.description}
        </p>
        <Dialog open={open} onClose={handleClick}>
          <IconButton
            aria-label="close"
            onClick={handleClick}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent
            dividers
            sx={{
              padding: "20px",
            }}
          >
            <p>{props.description}</p>
          </DialogContent>
        </Dialog>
      </Box>
    );
  };

  const columns: GridColDef[] = [
    // { field: "id", headerName: "ID" },
    {
      field: "actions",
      type: "actions",
      headerName: "",
      cellClassName: "actions",
      width: 100,
      getActions: ({ id }) => {
        return [
          <IconButton
            aria-label="delete"
            size="large"
            onClick={handleDeleteClick(id as string)}
          >
            <CancelIcon />
          </IconButton>,
        ];
      },
    },
    { field: "name", headerName: "Company Name", width: 400 },
    { field: "stockCode", headerName: "Stock Code", width: 200 },
    {
      field: "description",
      headerName: "Description",
      type: "string",
      width: 800,
      renderCell: (params) => {
        return (
          <DescriptionCell
            description={params.row.description}
          ></DescriptionCell>
        );
      },
    },
  ];

  const handleAddCompanies = (companies: SearchRelatedCompany[]) => {
    const tableData: SearchTableData[] = state.search.tableData ?? [];
    const newTableData = [
      ...tableData,
      ...companyInfosToSearchTableData(
        companies.filter(function (item, pos) {
          return (
            // item.id !== searchCompany.id &&
            !tableData.find((row) => row.id === item.id)
          );
        })
      ),
    ];

    dispatch({
      type: ReducerActionType.setSearchTableData,
      payload: {
        search: {
          tableData: newTableData,
        },
      },
    });
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <AddCompaniesDialog
          handleAddCompanies={handleAddCompanies}
        ></AddCompaniesDialog>
      </GridToolbarContainer>
    );
  };

  return (
    <>
      <h5>Comparable Companies:</h5>
      <div style={{ height: 700, width: "100%" }}>
        <DataGrid
          rows={state.search?.tableData ?? []}
          columns={columns}
          loading={state.search.tableLoading}
          slots={{
            toolbar: CustomToolbar,
          }}
        />
      </div>
    </>
  );
}
