import { SERVER } from "../utils/const";
import React from "react";
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloProvider,
  from,
  Operation,
} from "@apollo/client";
import { createNetworkStatusNotifier } from "react-apollo-network-status";
import { AvistaAuthLink } from "@avista/client-auth-link";
import { LoadingBackdrop } from "../components/LoadingBackdrop";
import Cookies from "js-cookie";

const { useApolloNetworkStatus, link: networkNotifierLink } =
  createNetworkStatusNotifier();
const GlobalNetworkMonitor: React.FC = () => {
  const { numPendingMutations, numPendingQueries } = useApolloNetworkStatus({
    shouldHandleOperation: (operation: Operation) => {
      return !operation.operationName.endsWith("DropdownOptions");
    },
  });

  if (numPendingQueries > 0 || numPendingMutations > 0) {
    return <LoadingBackdrop />;
  }
  return null;
};

const avistaAuthLink = new AvistaAuthLink(
  `${SERVER.LOGIN_HOST}`,
  `${SERVER.APP_URL}`
);

const getHttpLinkOption = () => {
  const authToken = Cookies.get(SERVER.JWT_COOKIE_NAME ?? "");
  if (authToken)
    return {
      uri: SERVER.GRAPHQL_URL,
      headers: {
        authorization: `Bearer ${authToken}`,
      },
    };
  return {
    uri: SERVER.GRAPHQL_URL,
  };
};

const apolloHttpLink = new HttpLink(getHttpLinkOption());

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([networkNotifierLink, avistaAuthLink.avistaLink, apolloHttpLink]),
});

const AvistaApolloProvider = ({ children }: React.PropsWithChildren) => (
  <ApolloProvider client={client}>
    <GlobalNetworkMonitor />
    {children}
  </ApolloProvider>
);

export default AvistaApolloProvider;
