import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { useApi } from "../../hooks";
import SearchResultTable from "../../components/SearchResultTable";
import {
  IReducerActions,
  IReducerState,
  ReducerActionType,
} from "../../utils/interface";
import { useEffect } from "react";
import { useMainContext } from "../../reducer/searchReducer";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useSearchResultHook } from "../../hooks/searchResultHook";

export const SearchResult = () => {
  const api = useApi();

  // get reducer from context
  const {
    state,
    dispatch,
  }: { state: IReducerState; dispatch: React.Dispatch<IReducerActions> } =
    useMainContext();

  const {
    feedbackSubmitClicked,
    searchCompanyUpdated,
    handleCopyTable,
    handleCopy,
  } = useSearchResultHook();

  useEffect(() => {
    dispatch({
      type: ReducerActionType.setTableLoading,
      payload: {
        search: {
          tableLoading: api.searchRelatedCompaniesLoading,
        },
      },
    });
  }, [api.searchRelatedCompaniesLoading, dispatch]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <IconButton onClick={searchCompanyUpdated}>
          <ArrowBackIosIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12} md={6} lg={8}>
        <h5>Target Company:</h5>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 400 }} size="small" aria-label="">
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  Name
                </TableCell>
                <TableCell>{state.search.searchCompany?.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Description
                </TableCell>
                <TableCell>{state.search.searchCompany?.description}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Website
                </TableCell>
                <TableCell>{state.search.searchCompany?.website}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12}>
        <SearchResultTable />
      </Grid>

      {state?.search?.tableData && state?.search?.tableData?.length > 0 && (
        <>
          <Grid item xs={12} sm={12} md={12}>
            <Button
              variant="outlined"
              sx={{ textTransform: "none" }}
              onClick={handleCopyTable}
            >
              Copy as Table
            </Button>
            <Button
              variant="outlined"
              sx={{ textTransform: "none" }}
              onClick={handleCopy}
            >
              Copy as Text
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Button
              variant="contained"
              sx={{ textTransform: "none" }}
              onClick={feedbackSubmitClicked}
            >
              Submit list for feedback
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};
