import { IconButton } from "@mui/material";
import { useCallback, useEffect } from "react";
import {
  SearchInfo,
  ReducerActionType,
  IReducerActions,
  IReducerState,
  SearchTableData,
} from "../utils/interface";
import { useMainContext } from "../reducer/searchReducer";
import { useApi, useSnackbar } from ".";
import CancelIcon from "@mui/icons-material/Cancel";
import { companyInfosToSearchTableData } from "../utils/helper";

export const useSearchPanelHook = () => {
  const api = useApi();
  // get reducer from context
  const {
    state,
    dispatch,
  }: { state: IReducerState; dispatch: React.Dispatch<IReducerActions> } =
    useMainContext();

  const { displaySnackbar } = useSnackbar();

  const handleCompanySearch = useCallback(() => {
    if (
      !state.search?.searchCompany?.description ||
      !state.search?.searchCompany?.name
    ) {
      displaySnackbar(
        "warning",
        "Please input target company name and description"
      );
      return;
    }
    // Send search request
    api.searchRelatedCompanies({
      variables: {
        searchString: state.search.searchCompany.description,
        model: state.search.searchCompany.model,
      },
    });
  }, [api, displaySnackbar, state.search.searchCompany]);

  const updateTargetCompany = useCallback(
    function updateTargetCompany(targetCompany: Partial<SearchInfo>) {
      dispatch({
        type: ReducerActionType.setSearchCompany,
        payload: {
          search: {
            searchCompany: {
              ...state.search.searchCompany,
              ...targetCompany,
            },
          },
        },
      });
    },
    [dispatch, state.search.searchCompany]
  );

  const getSearchClearBtn = useCallback(() => {
    if (state.search?.searchCompany?.description?.length)
      return (
        <IconButton
          aria-label="clear"
          onClick={() => {
            dispatch({
              type: ReducerActionType.setSearchCompany,
              payload: {
                search: {
                  searchCompany: {
                    ...state.search.searchCompany,
                    description: "",
                  },
                },
              },
            });
          }}
        >
          <CancelIcon />
        </IconButton>
      );
    else return <> </>;
  }, [dispatch, state.search.searchCompany]);

  const clearForm = () => {
    dispatch({
      type: ReducerActionType.setSearchCompany,
      payload: {
        search: {
          searchCompany: {
            ...state.search.searchCompany,
            name: "",
            website: "",
            description: "",
          },
        },
      },
    });
  };

  useEffect(
    function searchRelatedCompaniesDataUpdated() {
      if (!api.searchRelatedCompaniesData) return;
      const searchRelatedCompanies =
        api.searchRelatedCompaniesData?.searchRelatedCompanies ?? [];
      const tableData: SearchTableData[] = companyInfosToSearchTableData(
        searchRelatedCompanies
      );
      // Record AI data
      dispatch({
        type: ReducerActionType.setAiSearchData,
        payload: {
          search: {
            aiSearchData: tableData,
          },
        },
      });

      dispatch({
        type: ReducerActionType.setSearchTableData,
        payload: {
          search: {
            tableData,
          },
        },
      });
    },
    [api.searchRelatedCompaniesData, dispatch]
  );

  return {
    handleCompanySearch,
    updateTargetCompany,
    getSearchClearBtn,
    clearForm,
  };
};
