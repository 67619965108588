import { Button, Grid } from "@mui/material";
import { useApi } from "../../hooks";
import { AvistaSelect } from "@avista/avista-select";
import { IReducerActions, IReducerState } from "../../utils/interface";
import { useEffect, useState } from "react";
import { useMainContext } from "../../reducer/searchReducer";
import { useStyles } from "../../layout";
import { AvistaTextField } from "@avista/avista-text-field";
import { useSearchPanelHook } from "../../hooks/searchPanelHook";

export const SearchPanel = () => {
  const api = useApi();

  // get reducer from context
  const {
    state,
  }: { state: IReducerState; dispatch: React.Dispatch<IReducerActions> } =
    useMainContext();

  const {
    handleCompanySearch,
    updateTargetCompany,
    getSearchClearBtn,
    clearForm,
  } = useSearchPanelHook();
  const classes = useStyles();
  const [modelOptions, setModelOptions] = useState<
    { value: string; label: string; default: boolean }[]
  >([]);

  useEffect(function initModelOptions() {
    api.getOpenAiModels();
  }, []);

  useEffect(
    function updateModelOptions() {
      if (api.getOpenAiModelsData?.getOpenAiModels?.length) {
        const options: { value: string; label: string; default: boolean }[] =
          [];
        api.getOpenAiModelsData.getOpenAiModels.forEach((item, index) => {
          if (item && index === 0) updateTargetCompany({ model: item });
          if (item)
            options.push({
              value: item,
              label: item,
              default: index === 0,
            });
        });

        setModelOptions(options);
      }
    },
    [api.getOpenAiModelsData, updateTargetCompany]
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <AvistaSelect
          TextFieldProps={{
            // inputRef: searchModelInputRef,
            className: classes.input,
            style: {
              maxWidth: "500px",
            },
          }}
          onChange={(option) => {
            const opt = option as { value: string; label: string };
            if (opt) updateTargetCompany({ model: opt.value });
          }}
          options={modelOptions}
          value={modelOptions.find((item) => item.default)}
          isDisabled={true}
        />
      </Grid>
      <Grid item xs={12}>
        <AvistaTextField
          label="Company Name *"
          fullWidth
          className={classes.input}
          variant="new"
          onChange={(e) => updateTargetCompany({ name: e.target.value })}
          value={state.search?.searchCompany?.name}
        />
        <br />
        <AvistaTextField
          label="Company Description *"
          fullWidth
          className={classes.input}
          variant="new"
          onChange={(e) => updateTargetCompany({ description: e.target.value })}
          value={state.search?.searchCompany?.description}
          multiline
          minRows={5}
          InputProps={{
            endAdornment: getSearchClearBtn(),
          }}
        />
        <br />
        <AvistaTextField
          label="Company Website"
          fullWidth
          className={classes.input}
          variant="new"
          onChange={(e) => updateTargetCompany({ website: e.target.value })}
          value={state.search?.searchCompany?.website}
        />
      </Grid>

      <Grid item xs={4} md={6}>
        <Button size="medium" variant="contained" onClick={handleCompanySearch}>
          Search
        </Button>

        <Button
          style={{ marginLeft: "30px" }}
          size="medium"
          variant="outlined"
          onClick={clearForm}
        >
          Clear
        </Button>
      </Grid>
    </Grid>
  );
};
