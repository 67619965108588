const SERVER = {
  HOST: process.env.HOST,
  PORT: process.env.PORT,
  APP_URL: process.env.REACT_APP_URL,
  BACKEND_HOST: process.env.REACT_APP_BACKEND_HOST,
  GRAPHQL_URL: process.env.REACT_APP_GRAPHQL_URL,
  LOGIN_HOST: process.env.REACT_APP_LOGIN_HOST,
  JWT_COOKIE_NAME: process.env.REACT_APP_COOKIE_NAME,
  ADMIN_PROFILE_URL: process.env.REACT_APP_ADMIN_PROFILE_URL,
};


export { SERVER };
