import {
  SearchInfo,
  ISearchReducerState,
  ReducerActionType,
  SearchTableData,
} from "../utils/interface";
import { IReducerState, IReducerActions } from "../utils/interface";
import { MainContext } from "../provider/MainContext";
import { useContext } from "react";

const searchReducerInitState: ISearchReducerState = {
  searchCompany: {
    description: "",
    name: "",
    website: "",
    model: "",
  },
  tableData: [],
  tableLoading: false,
};

export const initialState: IReducerState = {
  snackbar: {
    text: "",
    isOpen: false,
    type: undefined,
    duration: 2000,
    redirectUrl: "",
  },
  ConfirmationDialog: {
    title: "",
    description: "",
    isOpen: false,
    button1Text: "",
    button2Text: "",
    button1Callback: () => {},
    button2Callback: () => {},
  },
  search: searchReducerInitState,
};

export const reducer = (
  state: IReducerState,
  action: IReducerActions
): IReducerState => {
  switch (action.type) {
    case ReducerActionType.hideSnackbar:
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          isOpen: false,
          redirectUrl: "",
        },
      };
    case ReducerActionType.showSnackbar:
      return {
        ...state,
        snackbar: {
          isOpen: true,
          text: action.payload?.snackbar?.text,
          type: action.payload?.snackbar?.type,
          redirectUrl: action.payload?.snackbar?.redirectUrl
            ? action.payload?.snackbar?.redirectUrl
            : "",
        },
      };
    case ReducerActionType.hideConfirmationDialog:
      return {
        ...state,
        ConfirmationDialog: {
          ...(action.payload?.ConfirmationDialog ?? state.ConfirmationDialog),
          isOpen: false,
        },
      };
    case ReducerActionType.showConfirmationDialog:
      return {
        ...state,
        ConfirmationDialog: {
          isOpen: true,
          ...action.payload?.ConfirmationDialog,
        },
      };
    case ReducerActionType.setSearchCompany:
      return {
        ...state,
        search: {
          ...state.search,
          searchCompany: action.payload?.search?.searchCompany,
        },
      };
    case ReducerActionType.setSearchTableData:
      return {
        ...state,
        search: {
          ...state.search,
          tableData: action.payload?.search?.tableData,
        },
      };
    case ReducerActionType.setAiSearchData:
      return {
        ...state,
        search: {
          ...state.search,
          aiSearchData: action.payload?.search?.aiSearchData,
        },
      };
    case ReducerActionType.setTableLoading:
      return {
        ...state,
        search: {
          ...state.search,
          tableLoading: false, //action.payload?.search?.tableLoading,
        },
      };
    default:
      throw new Error();
  }
};
export const useMainContext = () => {
  const reducer = useContext(MainContext);
  return reducer;
};

export type { ISearchReducerState };
export { ReducerActionType, searchReducerInitState };
