import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Json: any;
};

export type AggregateCompany = {
  _count?: Maybe<CompanyCountAggregateOutputType>;
  _max?: Maybe<CompanyMaxAggregateOutputType>;
  _min?: Maybe<CompanyMinAggregateOutputType>;
};

export type AggregateComparableAiSearch = {
  _count?: Maybe<ComparableAiSearchCountAggregateOutputType>;
  _max?: Maybe<ComparableAiSearchMaxAggregateOutputType>;
  _min?: Maybe<ComparableAiSearchMinAggregateOutputType>;
};

export type AggregateFeedbackData = {
  _count?: Maybe<FeedbackDataCountAggregateOutputType>;
  _max?: Maybe<FeedbackDataMaxAggregateOutputType>;
  _min?: Maybe<FeedbackDataMinAggregateOutputType>;
};

export type AggregateFineTuneRequest = {
  _avg?: Maybe<FineTuneRequestAvgAggregateOutputType>;
  _count?: Maybe<FineTuneRequestCountAggregateOutputType>;
  _max?: Maybe<FineTuneRequestMaxAggregateOutputType>;
  _min?: Maybe<FineTuneRequestMinAggregateOutputType>;
  _sum?: Maybe<FineTuneRequestSumAggregateOutputType>;
};

export type AggregateRelatedCompanyInTrainingData = {
  _count?: Maybe<RelatedCompanyInTrainingDataCountAggregateOutputType>;
  _max?: Maybe<RelatedCompanyInTrainingDataMaxAggregateOutputType>;
  _min?: Maybe<RelatedCompanyInTrainingDataMinAggregateOutputType>;
};

export type AggregateSimpleFineTuneRequest = {
  _avg?: Maybe<SimpleFineTuneRequestAvgAggregateOutputType>;
  _count?: Maybe<SimpleFineTuneRequestCountAggregateOutputType>;
  _max?: Maybe<SimpleFineTuneRequestMaxAggregateOutputType>;
  _min?: Maybe<SimpleFineTuneRequestMinAggregateOutputType>;
  _sum?: Maybe<SimpleFineTuneRequestSumAggregateOutputType>;
};

export type AggregateSimpleTrainingData = {
  _count?: Maybe<SimpleTrainingDataCountAggregateOutputType>;
  _max?: Maybe<SimpleTrainingDataMaxAggregateOutputType>;
  _min?: Maybe<SimpleTrainingDataMinAggregateOutputType>;
};

export type AggregateTrainingData = {
  _count?: Maybe<TrainingDataCountAggregateOutputType>;
  _max?: Maybe<TrainingDataMaxAggregateOutputType>;
  _min?: Maybe<TrainingDataMinAggregateOutputType>;
};

export type BatchPayload = {
  count: Scalars['Int'];
};

export type Company = {
  _count: CompanyCountOutputType;
  companyType?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  nativeName?: Maybe<Scalars['String']>;
  primaryIndustry?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  stockExchange: Scalars['String'];
  ticker: Scalars['String'];
  trainedRelatedCompanies: Array<RelatedCompanyInTrainingData>;
  website?: Maybe<Scalars['String']>;
};


export type CompanyTrainedRelatedCompaniesArgs = {
  cursor?: InputMaybe<RelatedCompanyInTrainingDataWhereUniqueInput>;
  distinct?: InputMaybe<RelatedCompanyInTrainingDataScalarFieldEnum>;
  orderBy?: InputMaybe<RelatedCompanyInTrainingDataOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RelatedCompanyInTrainingDataWhereInput>;
};

export type CompanyCountAggregateOutputType = {
  _all: Scalars['Int'];
  companyType: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  nativeName: Scalars['Int'];
  primaryIndustry: Scalars['Int'];
  region: Scalars['Int'];
  stockExchange: Scalars['Int'];
  ticker: Scalars['Int'];
  website: Scalars['Int'];
};

export type CompanyCountOrderByAggregateInput = {
  companyType?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  nativeName?: InputMaybe<SortOrder>;
  primaryIndustry?: InputMaybe<SortOrder>;
  region?: InputMaybe<SortOrder>;
  stockExchange?: InputMaybe<SortOrder>;
  ticker?: InputMaybe<SortOrder>;
  website?: InputMaybe<SortOrder>;
};

export type CompanyCountOutputType = {
  trainedRelatedCompanies: Scalars['Int'];
};

export type CompanyCreateInput = {
  companyType?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  nativeName?: InputMaybe<Scalars['String']>;
  primaryIndustry?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  stockExchange: Scalars['String'];
  ticker: Scalars['String'];
  trainedRelatedCompanies?: InputMaybe<RelatedCompanyInTrainingDataCreateNestedManyWithoutRelatedCompaniesInput>;
  website?: InputMaybe<Scalars['String']>;
};

export type CompanyCreateManyInput = {
  companyType?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  nativeName?: InputMaybe<Scalars['String']>;
  primaryIndustry?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  stockExchange: Scalars['String'];
  ticker: Scalars['String'];
  website?: InputMaybe<Scalars['String']>;
};

export type CompanyCreateNestedOneWithoutTrainedRelatedCompaniesInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyCreateOrConnectWithoutTrainedRelatedCompaniesInput>;
  create?: InputMaybe<CompanyUncheckedCreateWithoutTrainedRelatedCompaniesInput>;
};

export type CompanyCreateOrConnectWithoutTrainedRelatedCompaniesInput = {
  create: CompanyUncheckedCreateWithoutTrainedRelatedCompaniesInput;
  where: CompanyWhereUniqueInput;
};

export type CompanyCreateWithoutTrainedRelatedCompaniesInput = {
  companyType?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  nativeName?: InputMaybe<Scalars['String']>;
  primaryIndustry?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  stockExchange: Scalars['String'];
  ticker: Scalars['String'];
  website?: InputMaybe<Scalars['String']>;
};

export type CompanyMaxAggregateOutputType = {
  companyType?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nativeName?: Maybe<Scalars['String']>;
  primaryIndustry?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  stockExchange?: Maybe<Scalars['String']>;
  ticker?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type CompanyMaxOrderByAggregateInput = {
  companyType?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  nativeName?: InputMaybe<SortOrder>;
  primaryIndustry?: InputMaybe<SortOrder>;
  region?: InputMaybe<SortOrder>;
  stockExchange?: InputMaybe<SortOrder>;
  ticker?: InputMaybe<SortOrder>;
  website?: InputMaybe<SortOrder>;
};

export type CompanyMinAggregateOutputType = {
  companyType?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nativeName?: Maybe<Scalars['String']>;
  primaryIndustry?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  stockExchange?: Maybe<Scalars['String']>;
  ticker?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type CompanyMinOrderByAggregateInput = {
  companyType?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  nativeName?: InputMaybe<SortOrder>;
  primaryIndustry?: InputMaybe<SortOrder>;
  region?: InputMaybe<SortOrder>;
  stockExchange?: InputMaybe<SortOrder>;
  ticker?: InputMaybe<SortOrder>;
  website?: InputMaybe<SortOrder>;
};

export type CompanyOrderByWithAggregationInput = {
  _count?: InputMaybe<CompanyCountOrderByAggregateInput>;
  _max?: InputMaybe<CompanyMaxOrderByAggregateInput>;
  _min?: InputMaybe<CompanyMinOrderByAggregateInput>;
  companyType?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  nativeName?: InputMaybe<SortOrder>;
  primaryIndustry?: InputMaybe<SortOrder>;
  region?: InputMaybe<SortOrder>;
  stockExchange?: InputMaybe<SortOrder>;
  ticker?: InputMaybe<SortOrder>;
  website?: InputMaybe<SortOrder>;
};

export type CompanyOrderByWithRelationInput = {
  companyType?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  nativeName?: InputMaybe<SortOrder>;
  primaryIndustry?: InputMaybe<SortOrder>;
  region?: InputMaybe<SortOrder>;
  stockExchange?: InputMaybe<SortOrder>;
  ticker?: InputMaybe<SortOrder>;
  trainedRelatedCompanies?: InputMaybe<RelatedCompanyInTrainingDataOrderByRelationAggregateInput>;
  website?: InputMaybe<SortOrder>;
};

export type CompanyRelationFilter = {
  is?: InputMaybe<CompanyWhereInput>;
  isNot?: InputMaybe<CompanyWhereInput>;
};

export enum CompanyScalarFieldEnum {
  CompanyType = 'companyType',
  Description = 'description',
  Id = 'id',
  Name = 'name',
  NativeName = 'nativeName',
  PrimaryIndustry = 'primaryIndustry',
  Region = 'region',
  StockExchange = 'stockExchange',
  Ticker = 'ticker',
  Website = 'website'
}

export type CompanyScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CompanyScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CompanyScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CompanyScalarWhereWithAggregatesInput>>;
  companyType?: InputMaybe<StringNullableWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  nativeName?: InputMaybe<StringNullableWithAggregatesFilter>;
  primaryIndustry?: InputMaybe<StringNullableWithAggregatesFilter>;
  region?: InputMaybe<StringNullableWithAggregatesFilter>;
  stockExchange?: InputMaybe<StringWithAggregatesFilter>;
  ticker?: InputMaybe<StringWithAggregatesFilter>;
  website?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type CompanyStockExchangeTickerCompoundUniqueInput = {
  stockExchange: Scalars['String'];
  ticker: Scalars['String'];
};

export type CompanyUncheckedCreateInput = {
  companyType?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  nativeName?: InputMaybe<Scalars['String']>;
  primaryIndustry?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  stockExchange: Scalars['String'];
  ticker: Scalars['String'];
  trainedRelatedCompanies?: InputMaybe<RelatedCompanyInTrainingDataUncheckedCreateNestedManyWithoutRelatedCompaniesInput>;
  website?: InputMaybe<Scalars['String']>;
};

export type CompanyUncheckedCreateWithoutTrainedRelatedCompaniesInput = {
  companyType?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  nativeName?: InputMaybe<Scalars['String']>;
  primaryIndustry?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  stockExchange: Scalars['String'];
  ticker: Scalars['String'];
  website?: InputMaybe<Scalars['String']>;
};

export type CompanyUncheckedUpdateInput = {
  companyType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  nativeName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primaryIndustry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  region?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stockExchange?: InputMaybe<StringFieldUpdateOperationsInput>;
  ticker?: InputMaybe<StringFieldUpdateOperationsInput>;
  trainedRelatedCompanies?: InputMaybe<RelatedCompanyInTrainingDataUncheckedUpdateManyWithoutRelatedCompaniesNestedInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CompanyUncheckedUpdateManyInput = {
  companyType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  nativeName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primaryIndustry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  region?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stockExchange?: InputMaybe<StringFieldUpdateOperationsInput>;
  ticker?: InputMaybe<StringFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CompanyUncheckedUpdateWithoutTrainedRelatedCompaniesInput = {
  companyType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  nativeName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primaryIndustry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  region?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stockExchange?: InputMaybe<StringFieldUpdateOperationsInput>;
  ticker?: InputMaybe<StringFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CompanyUpdateInput = {
  companyType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  nativeName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primaryIndustry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  region?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stockExchange?: InputMaybe<StringFieldUpdateOperationsInput>;
  ticker?: InputMaybe<StringFieldUpdateOperationsInput>;
  trainedRelatedCompanies?: InputMaybe<RelatedCompanyInTrainingDataUpdateManyWithoutRelatedCompaniesNestedInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CompanyUpdateManyMutationInput = {
  companyType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  nativeName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primaryIndustry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  region?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stockExchange?: InputMaybe<StringFieldUpdateOperationsInput>;
  ticker?: InputMaybe<StringFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CompanyUpdateOneRequiredWithoutTrainedRelatedCompaniesNestedInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyCreateOrConnectWithoutTrainedRelatedCompaniesInput>;
  create?: InputMaybe<CompanyUncheckedCreateWithoutTrainedRelatedCompaniesInput>;
  update?: InputMaybe<CompanyUncheckedUpdateWithoutTrainedRelatedCompaniesInput>;
  upsert?: InputMaybe<CompanyUpsertWithoutTrainedRelatedCompaniesInput>;
};

export type CompanyUpdateWithoutTrainedRelatedCompaniesInput = {
  companyType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  nativeName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primaryIndustry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  region?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stockExchange?: InputMaybe<StringFieldUpdateOperationsInput>;
  ticker?: InputMaybe<StringFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CompanyUpsertWithoutTrainedRelatedCompaniesInput = {
  create: CompanyUncheckedCreateWithoutTrainedRelatedCompaniesInput;
  update: CompanyUncheckedUpdateWithoutTrainedRelatedCompaniesInput;
};

export type CompanyWhereInput = {
  AND?: InputMaybe<Array<CompanyWhereInput>>;
  NOT?: InputMaybe<Array<CompanyWhereInput>>;
  OR?: InputMaybe<Array<CompanyWhereInput>>;
  companyType?: InputMaybe<StringNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  nativeName?: InputMaybe<StringNullableFilter>;
  primaryIndustry?: InputMaybe<StringNullableFilter>;
  region?: InputMaybe<StringNullableFilter>;
  stockExchange?: InputMaybe<StringFilter>;
  ticker?: InputMaybe<StringFilter>;
  trainedRelatedCompanies?: InputMaybe<RelatedCompanyInTrainingDataListRelationFilter>;
  website?: InputMaybe<StringNullableFilter>;
};

export type CompanyWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  stockExchange_ticker?: InputMaybe<CompanyStockExchangeTickerCompoundUniqueInput>;
};

export type ComparableAiSearch = {
  comparableAiCompanyCountry?: Maybe<Scalars['String']>;
  comparableAiCompanyDescription?: Maybe<Scalars['String']>;
  comparableAiCompanyDetails?: Maybe<Scalars['Json']>;
  comparableAiCompanyID?: Maybe<Scalars['String']>;
  comparableAiCompanyStandardName?: Maybe<Scalars['String']>;
  global_model_search?: Maybe<Scalars['Json']>;
  id: Scalars['String'];
  industry_search?: Maybe<Scalars['Json']>;
  keyword_search?: Maybe<Scalars['Json']>;
  ml_search?: Maybe<Scalars['Json']>;
  name: Scalars['String'];
  nativeName?: Maybe<Scalars['String']>;
  peer_graph_search?: Maybe<Scalars['Json']>;
  products_services_search?: Maybe<Scalars['Json']>;
  semantic_search?: Maybe<Scalars['Json']>;
  stockExchange: Scalars['String'];
  ticker: Scalars['String'];
};

export type ComparableAiSearchCountAggregateOutputType = {
  _all: Scalars['Int'];
  comparableAiCompanyCountry: Scalars['Int'];
  comparableAiCompanyDescription: Scalars['Int'];
  comparableAiCompanyDetails: Scalars['Int'];
  comparableAiCompanyID: Scalars['Int'];
  comparableAiCompanyStandardName: Scalars['Int'];
  global_model_search: Scalars['Int'];
  id: Scalars['Int'];
  industry_search: Scalars['Int'];
  keyword_search: Scalars['Int'];
  ml_search: Scalars['Int'];
  name: Scalars['Int'];
  nativeName: Scalars['Int'];
  peer_graph_search: Scalars['Int'];
  products_services_search: Scalars['Int'];
  semantic_search: Scalars['Int'];
  stockExchange: Scalars['Int'];
  ticker: Scalars['Int'];
};

export type ComparableAiSearchCountOrderByAggregateInput = {
  comparableAiCompanyCountry?: InputMaybe<SortOrder>;
  comparableAiCompanyDescription?: InputMaybe<SortOrder>;
  comparableAiCompanyDetails?: InputMaybe<SortOrder>;
  comparableAiCompanyID?: InputMaybe<SortOrder>;
  comparableAiCompanyStandardName?: InputMaybe<SortOrder>;
  global_model_search?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  industry_search?: InputMaybe<SortOrder>;
  keyword_search?: InputMaybe<SortOrder>;
  ml_search?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  nativeName?: InputMaybe<SortOrder>;
  peer_graph_search?: InputMaybe<SortOrder>;
  products_services_search?: InputMaybe<SortOrder>;
  semantic_search?: InputMaybe<SortOrder>;
  stockExchange?: InputMaybe<SortOrder>;
  ticker?: InputMaybe<SortOrder>;
};

export type ComparableAiSearchCreateInput = {
  comparableAiCompanyCountry?: InputMaybe<Scalars['String']>;
  comparableAiCompanyDescription?: InputMaybe<Scalars['String']>;
  comparableAiCompanyDetails?: InputMaybe<Scalars['Json']>;
  comparableAiCompanyID?: InputMaybe<Scalars['String']>;
  comparableAiCompanyStandardName?: InputMaybe<Scalars['String']>;
  global_model_search?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<Scalars['String']>;
  industry_search?: InputMaybe<Scalars['Json']>;
  keyword_search?: InputMaybe<Scalars['Json']>;
  ml_search?: InputMaybe<Scalars['Json']>;
  name: Scalars['String'];
  nativeName?: InputMaybe<Scalars['String']>;
  peer_graph_search?: InputMaybe<Scalars['Json']>;
  products_services_search?: InputMaybe<Scalars['Json']>;
  semantic_search?: InputMaybe<Scalars['Json']>;
  stockExchange: Scalars['String'];
  ticker: Scalars['String'];
};

export type ComparableAiSearchCreateManyInput = {
  comparableAiCompanyCountry?: InputMaybe<Scalars['String']>;
  comparableAiCompanyDescription?: InputMaybe<Scalars['String']>;
  comparableAiCompanyDetails?: InputMaybe<Scalars['Json']>;
  comparableAiCompanyID?: InputMaybe<Scalars['String']>;
  comparableAiCompanyStandardName?: InputMaybe<Scalars['String']>;
  global_model_search?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<Scalars['String']>;
  industry_search?: InputMaybe<Scalars['Json']>;
  keyword_search?: InputMaybe<Scalars['Json']>;
  ml_search?: InputMaybe<Scalars['Json']>;
  name: Scalars['String'];
  nativeName?: InputMaybe<Scalars['String']>;
  peer_graph_search?: InputMaybe<Scalars['Json']>;
  products_services_search?: InputMaybe<Scalars['Json']>;
  semantic_search?: InputMaybe<Scalars['Json']>;
  stockExchange: Scalars['String'];
  ticker: Scalars['String'];
};

export type ComparableAiSearchMaxAggregateOutputType = {
  comparableAiCompanyCountry?: Maybe<Scalars['String']>;
  comparableAiCompanyDescription?: Maybe<Scalars['String']>;
  comparableAiCompanyID?: Maybe<Scalars['String']>;
  comparableAiCompanyStandardName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nativeName?: Maybe<Scalars['String']>;
  stockExchange?: Maybe<Scalars['String']>;
  ticker?: Maybe<Scalars['String']>;
};

export type ComparableAiSearchMaxOrderByAggregateInput = {
  comparableAiCompanyCountry?: InputMaybe<SortOrder>;
  comparableAiCompanyDescription?: InputMaybe<SortOrder>;
  comparableAiCompanyID?: InputMaybe<SortOrder>;
  comparableAiCompanyStandardName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  nativeName?: InputMaybe<SortOrder>;
  stockExchange?: InputMaybe<SortOrder>;
  ticker?: InputMaybe<SortOrder>;
};

export type ComparableAiSearchMinAggregateOutputType = {
  comparableAiCompanyCountry?: Maybe<Scalars['String']>;
  comparableAiCompanyDescription?: Maybe<Scalars['String']>;
  comparableAiCompanyID?: Maybe<Scalars['String']>;
  comparableAiCompanyStandardName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nativeName?: Maybe<Scalars['String']>;
  stockExchange?: Maybe<Scalars['String']>;
  ticker?: Maybe<Scalars['String']>;
};

export type ComparableAiSearchMinOrderByAggregateInput = {
  comparableAiCompanyCountry?: InputMaybe<SortOrder>;
  comparableAiCompanyDescription?: InputMaybe<SortOrder>;
  comparableAiCompanyID?: InputMaybe<SortOrder>;
  comparableAiCompanyStandardName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  nativeName?: InputMaybe<SortOrder>;
  stockExchange?: InputMaybe<SortOrder>;
  ticker?: InputMaybe<SortOrder>;
};

export type ComparableAiSearchOrderByWithAggregationInput = {
  _count?: InputMaybe<ComparableAiSearchCountOrderByAggregateInput>;
  _max?: InputMaybe<ComparableAiSearchMaxOrderByAggregateInput>;
  _min?: InputMaybe<ComparableAiSearchMinOrderByAggregateInput>;
  comparableAiCompanyCountry?: InputMaybe<SortOrder>;
  comparableAiCompanyDescription?: InputMaybe<SortOrder>;
  comparableAiCompanyDetails?: InputMaybe<SortOrder>;
  comparableAiCompanyID?: InputMaybe<SortOrder>;
  comparableAiCompanyStandardName?: InputMaybe<SortOrder>;
  global_model_search?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  industry_search?: InputMaybe<SortOrder>;
  keyword_search?: InputMaybe<SortOrder>;
  ml_search?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  nativeName?: InputMaybe<SortOrder>;
  peer_graph_search?: InputMaybe<SortOrder>;
  products_services_search?: InputMaybe<SortOrder>;
  semantic_search?: InputMaybe<SortOrder>;
  stockExchange?: InputMaybe<SortOrder>;
  ticker?: InputMaybe<SortOrder>;
};

export type ComparableAiSearchOrderByWithRelationInput = {
  comparableAiCompanyCountry?: InputMaybe<SortOrder>;
  comparableAiCompanyDescription?: InputMaybe<SortOrder>;
  comparableAiCompanyDetails?: InputMaybe<SortOrder>;
  comparableAiCompanyID?: InputMaybe<SortOrder>;
  comparableAiCompanyStandardName?: InputMaybe<SortOrder>;
  global_model_search?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  industry_search?: InputMaybe<SortOrder>;
  keyword_search?: InputMaybe<SortOrder>;
  ml_search?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  nativeName?: InputMaybe<SortOrder>;
  peer_graph_search?: InputMaybe<SortOrder>;
  products_services_search?: InputMaybe<SortOrder>;
  semantic_search?: InputMaybe<SortOrder>;
  stockExchange?: InputMaybe<SortOrder>;
  ticker?: InputMaybe<SortOrder>;
};

export enum ComparableAiSearchScalarFieldEnum {
  ComparableAiCompanyCountry = 'comparableAiCompanyCountry',
  ComparableAiCompanyDescription = 'comparableAiCompanyDescription',
  ComparableAiCompanyDetails = 'comparableAiCompanyDetails',
  ComparableAiCompanyId = 'comparableAiCompanyID',
  ComparableAiCompanyStandardName = 'comparableAiCompanyStandardName',
  GlobalModelSearch = 'global_model_search',
  Id = 'id',
  IndustrySearch = 'industry_search',
  KeywordSearch = 'keyword_search',
  MlSearch = 'ml_search',
  Name = 'name',
  NativeName = 'nativeName',
  PeerGraphSearch = 'peer_graph_search',
  ProductsServicesSearch = 'products_services_search',
  SemanticSearch = 'semantic_search',
  StockExchange = 'stockExchange',
  Ticker = 'ticker'
}

export type ComparableAiSearchScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ComparableAiSearchScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ComparableAiSearchScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ComparableAiSearchScalarWhereWithAggregatesInput>>;
  comparableAiCompanyCountry?: InputMaybe<StringNullableWithAggregatesFilter>;
  comparableAiCompanyDescription?: InputMaybe<StringNullableWithAggregatesFilter>;
  comparableAiCompanyDetails?: InputMaybe<JsonNullableWithAggregatesFilter>;
  comparableAiCompanyID?: InputMaybe<StringNullableWithAggregatesFilter>;
  comparableAiCompanyStandardName?: InputMaybe<StringNullableWithAggregatesFilter>;
  global_model_search?: InputMaybe<JsonNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  industry_search?: InputMaybe<JsonNullableWithAggregatesFilter>;
  keyword_search?: InputMaybe<JsonNullableWithAggregatesFilter>;
  ml_search?: InputMaybe<JsonNullableWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  nativeName?: InputMaybe<StringNullableWithAggregatesFilter>;
  peer_graph_search?: InputMaybe<JsonNullableWithAggregatesFilter>;
  products_services_search?: InputMaybe<JsonNullableWithAggregatesFilter>;
  semantic_search?: InputMaybe<JsonNullableWithAggregatesFilter>;
  stockExchange?: InputMaybe<StringWithAggregatesFilter>;
  ticker?: InputMaybe<StringWithAggregatesFilter>;
};

export type ComparableAiSearchUncheckedCreateInput = {
  comparableAiCompanyCountry?: InputMaybe<Scalars['String']>;
  comparableAiCompanyDescription?: InputMaybe<Scalars['String']>;
  comparableAiCompanyDetails?: InputMaybe<Scalars['Json']>;
  comparableAiCompanyID?: InputMaybe<Scalars['String']>;
  comparableAiCompanyStandardName?: InputMaybe<Scalars['String']>;
  global_model_search?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<Scalars['String']>;
  industry_search?: InputMaybe<Scalars['Json']>;
  keyword_search?: InputMaybe<Scalars['Json']>;
  ml_search?: InputMaybe<Scalars['Json']>;
  name: Scalars['String'];
  nativeName?: InputMaybe<Scalars['String']>;
  peer_graph_search?: InputMaybe<Scalars['Json']>;
  products_services_search?: InputMaybe<Scalars['Json']>;
  semantic_search?: InputMaybe<Scalars['Json']>;
  stockExchange: Scalars['String'];
  ticker: Scalars['String'];
};

export type ComparableAiSearchUncheckedUpdateInput = {
  comparableAiCompanyCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  comparableAiCompanyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  comparableAiCompanyDetails?: InputMaybe<Scalars['Json']>;
  comparableAiCompanyID?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  comparableAiCompanyStandardName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  global_model_search?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  industry_search?: InputMaybe<Scalars['Json']>;
  keyword_search?: InputMaybe<Scalars['Json']>;
  ml_search?: InputMaybe<Scalars['Json']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  nativeName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  peer_graph_search?: InputMaybe<Scalars['Json']>;
  products_services_search?: InputMaybe<Scalars['Json']>;
  semantic_search?: InputMaybe<Scalars['Json']>;
  stockExchange?: InputMaybe<StringFieldUpdateOperationsInput>;
  ticker?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ComparableAiSearchUncheckedUpdateManyInput = {
  comparableAiCompanyCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  comparableAiCompanyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  comparableAiCompanyDetails?: InputMaybe<Scalars['Json']>;
  comparableAiCompanyID?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  comparableAiCompanyStandardName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  global_model_search?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  industry_search?: InputMaybe<Scalars['Json']>;
  keyword_search?: InputMaybe<Scalars['Json']>;
  ml_search?: InputMaybe<Scalars['Json']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  nativeName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  peer_graph_search?: InputMaybe<Scalars['Json']>;
  products_services_search?: InputMaybe<Scalars['Json']>;
  semantic_search?: InputMaybe<Scalars['Json']>;
  stockExchange?: InputMaybe<StringFieldUpdateOperationsInput>;
  ticker?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ComparableAiSearchUpdateInput = {
  comparableAiCompanyCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  comparableAiCompanyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  comparableAiCompanyDetails?: InputMaybe<Scalars['Json']>;
  comparableAiCompanyID?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  comparableAiCompanyStandardName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  global_model_search?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  industry_search?: InputMaybe<Scalars['Json']>;
  keyword_search?: InputMaybe<Scalars['Json']>;
  ml_search?: InputMaybe<Scalars['Json']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  nativeName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  peer_graph_search?: InputMaybe<Scalars['Json']>;
  products_services_search?: InputMaybe<Scalars['Json']>;
  semantic_search?: InputMaybe<Scalars['Json']>;
  stockExchange?: InputMaybe<StringFieldUpdateOperationsInput>;
  ticker?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ComparableAiSearchUpdateManyMutationInput = {
  comparableAiCompanyCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  comparableAiCompanyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  comparableAiCompanyDetails?: InputMaybe<Scalars['Json']>;
  comparableAiCompanyID?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  comparableAiCompanyStandardName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  global_model_search?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  industry_search?: InputMaybe<Scalars['Json']>;
  keyword_search?: InputMaybe<Scalars['Json']>;
  ml_search?: InputMaybe<Scalars['Json']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  nativeName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  peer_graph_search?: InputMaybe<Scalars['Json']>;
  products_services_search?: InputMaybe<Scalars['Json']>;
  semantic_search?: InputMaybe<Scalars['Json']>;
  stockExchange?: InputMaybe<StringFieldUpdateOperationsInput>;
  ticker?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ComparableAiSearchWhereInput = {
  AND?: InputMaybe<Array<ComparableAiSearchWhereInput>>;
  NOT?: InputMaybe<Array<ComparableAiSearchWhereInput>>;
  OR?: InputMaybe<Array<ComparableAiSearchWhereInput>>;
  comparableAiCompanyCountry?: InputMaybe<StringNullableFilter>;
  comparableAiCompanyDescription?: InputMaybe<StringNullableFilter>;
  comparableAiCompanyDetails?: InputMaybe<JsonNullableFilter>;
  comparableAiCompanyID?: InputMaybe<StringNullableFilter>;
  comparableAiCompanyStandardName?: InputMaybe<StringNullableFilter>;
  global_model_search?: InputMaybe<JsonNullableFilter>;
  id?: InputMaybe<StringFilter>;
  industry_search?: InputMaybe<JsonNullableFilter>;
  keyword_search?: InputMaybe<JsonNullableFilter>;
  ml_search?: InputMaybe<JsonNullableFilter>;
  name?: InputMaybe<StringFilter>;
  nativeName?: InputMaybe<StringNullableFilter>;
  peer_graph_search?: InputMaybe<JsonNullableFilter>;
  products_services_search?: InputMaybe<JsonNullableFilter>;
  semantic_search?: InputMaybe<JsonNullableFilter>;
  stockExchange?: InputMaybe<StringFilter>;
  ticker?: InputMaybe<StringFilter>;
};

export type ComparableAiSearchWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDateTimeNullableFilter>;
  _min?: InputMaybe<NestedDateTimeNullableFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type FeedbackData = {
  advisedRelatedCompanies: Array<Scalars['String']>;
  advisedRelatedCompaniesStockCode: Array<Scalars['String']>;
  aiRelatedCompanies: Array<Scalars['String']>;
  aiRelatedCompaniesStockCode: Array<Scalars['String']>;
  approvedAt?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  companyDescription: Scalars['String'];
  companyWebsite?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  relatedCompanies: Array<Scalars['String']>;
  relatedCompaniesStockCode: Array<Scalars['String']>;
};

export type FeedbackDataCountAggregateOutputType = {
  _all: Scalars['Int'];
  advisedRelatedCompanies: Scalars['Int'];
  advisedRelatedCompaniesStockCode: Scalars['Int'];
  aiRelatedCompanies: Scalars['Int'];
  aiRelatedCompaniesStockCode: Scalars['Int'];
  approvedAt: Scalars['Int'];
  approvedBy: Scalars['Int'];
  company: Scalars['Int'];
  companyDescription: Scalars['Int'];
  companyWebsite: Scalars['Int'];
  createdAt: Scalars['Int'];
  createdBy: Scalars['Int'];
  id: Scalars['Int'];
  relatedCompanies: Scalars['Int'];
  relatedCompaniesStockCode: Scalars['Int'];
};

export type FeedbackDataCountOrderByAggregateInput = {
  advisedRelatedCompanies?: InputMaybe<SortOrder>;
  advisedRelatedCompaniesStockCode?: InputMaybe<SortOrder>;
  aiRelatedCompanies?: InputMaybe<SortOrder>;
  aiRelatedCompaniesStockCode?: InputMaybe<SortOrder>;
  approvedAt?: InputMaybe<SortOrder>;
  approvedBy?: InputMaybe<SortOrder>;
  company?: InputMaybe<SortOrder>;
  companyDescription?: InputMaybe<SortOrder>;
  companyWebsite?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  relatedCompanies?: InputMaybe<SortOrder>;
  relatedCompaniesStockCode?: InputMaybe<SortOrder>;
};

export type FeedbackDataCreateInput = {
  advisedRelatedCompanies?: InputMaybe<Array<Scalars['String']>>;
  advisedRelatedCompaniesStockCode?: InputMaybe<Array<Scalars['String']>>;
  aiRelatedCompanies?: InputMaybe<Array<Scalars['String']>>;
  aiRelatedCompaniesStockCode?: InputMaybe<Array<Scalars['String']>>;
  approvedAt?: InputMaybe<Scalars['DateTime']>;
  approvedBy?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  companyDescription: Scalars['String'];
  companyWebsite?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  relatedCompanies?: InputMaybe<Array<Scalars['String']>>;
  relatedCompaniesStockCode?: InputMaybe<Array<Scalars['String']>>;
};

export type FeedbackDataCreateManyInput = {
  advisedRelatedCompanies?: InputMaybe<Array<Scalars['String']>>;
  advisedRelatedCompaniesStockCode?: InputMaybe<Array<Scalars['String']>>;
  aiRelatedCompanies?: InputMaybe<Array<Scalars['String']>>;
  aiRelatedCompaniesStockCode?: InputMaybe<Array<Scalars['String']>>;
  approvedAt?: InputMaybe<Scalars['DateTime']>;
  approvedBy?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  companyDescription: Scalars['String'];
  companyWebsite?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  relatedCompanies?: InputMaybe<Array<Scalars['String']>>;
  relatedCompaniesStockCode?: InputMaybe<Array<Scalars['String']>>;
};

export type FeedbackDataCreateadvisedRelatedCompaniesInput = {
  set: Array<Scalars['String']>;
};

export type FeedbackDataCreateadvisedRelatedCompaniesStockCodeInput = {
  set: Array<Scalars['String']>;
};

export type FeedbackDataCreateaiRelatedCompaniesInput = {
  set: Array<Scalars['String']>;
};

export type FeedbackDataCreateaiRelatedCompaniesStockCodeInput = {
  set: Array<Scalars['String']>;
};

export type FeedbackDataCreaterelatedCompaniesInput = {
  set: Array<Scalars['String']>;
};

export type FeedbackDataCreaterelatedCompaniesStockCodeInput = {
  set: Array<Scalars['String']>;
};

export type FeedbackDataMaxAggregateOutputType = {
  approvedAt?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  companyDescription?: Maybe<Scalars['String']>;
  companyWebsite?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type FeedbackDataMaxOrderByAggregateInput = {
  approvedAt?: InputMaybe<SortOrder>;
  approvedBy?: InputMaybe<SortOrder>;
  company?: InputMaybe<SortOrder>;
  companyDescription?: InputMaybe<SortOrder>;
  companyWebsite?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type FeedbackDataMinAggregateOutputType = {
  approvedAt?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  companyDescription?: Maybe<Scalars['String']>;
  companyWebsite?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type FeedbackDataMinOrderByAggregateInput = {
  approvedAt?: InputMaybe<SortOrder>;
  approvedBy?: InputMaybe<SortOrder>;
  company?: InputMaybe<SortOrder>;
  companyDescription?: InputMaybe<SortOrder>;
  companyWebsite?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type FeedbackDataOrderByWithAggregationInput = {
  _count?: InputMaybe<FeedbackDataCountOrderByAggregateInput>;
  _max?: InputMaybe<FeedbackDataMaxOrderByAggregateInput>;
  _min?: InputMaybe<FeedbackDataMinOrderByAggregateInput>;
  advisedRelatedCompanies?: InputMaybe<SortOrder>;
  advisedRelatedCompaniesStockCode?: InputMaybe<SortOrder>;
  aiRelatedCompanies?: InputMaybe<SortOrder>;
  aiRelatedCompaniesStockCode?: InputMaybe<SortOrder>;
  approvedAt?: InputMaybe<SortOrder>;
  approvedBy?: InputMaybe<SortOrder>;
  company?: InputMaybe<SortOrder>;
  companyDescription?: InputMaybe<SortOrder>;
  companyWebsite?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  relatedCompanies?: InputMaybe<SortOrder>;
  relatedCompaniesStockCode?: InputMaybe<SortOrder>;
};

export type FeedbackDataOrderByWithRelationInput = {
  advisedRelatedCompanies?: InputMaybe<SortOrder>;
  advisedRelatedCompaniesStockCode?: InputMaybe<SortOrder>;
  aiRelatedCompanies?: InputMaybe<SortOrder>;
  aiRelatedCompaniesStockCode?: InputMaybe<SortOrder>;
  approvedAt?: InputMaybe<SortOrder>;
  approvedBy?: InputMaybe<SortOrder>;
  company?: InputMaybe<SortOrder>;
  companyDescription?: InputMaybe<SortOrder>;
  companyWebsite?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  relatedCompanies?: InputMaybe<SortOrder>;
  relatedCompaniesStockCode?: InputMaybe<SortOrder>;
};

export enum FeedbackDataScalarFieldEnum {
  AdvisedRelatedCompanies = 'advisedRelatedCompanies',
  AdvisedRelatedCompaniesStockCode = 'advisedRelatedCompaniesStockCode',
  AiRelatedCompanies = 'aiRelatedCompanies',
  AiRelatedCompaniesStockCode = 'aiRelatedCompaniesStockCode',
  ApprovedAt = 'approvedAt',
  ApprovedBy = 'approvedBy',
  Company = 'company',
  CompanyDescription = 'companyDescription',
  CompanyWebsite = 'companyWebsite',
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  Id = 'id',
  RelatedCompanies = 'relatedCompanies',
  RelatedCompaniesStockCode = 'relatedCompaniesStockCode'
}

export type FeedbackDataScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<FeedbackDataScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<FeedbackDataScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<FeedbackDataScalarWhereWithAggregatesInput>>;
  advisedRelatedCompanies?: InputMaybe<StringNullableListFilter>;
  advisedRelatedCompaniesStockCode?: InputMaybe<StringNullableListFilter>;
  aiRelatedCompanies?: InputMaybe<StringNullableListFilter>;
  aiRelatedCompaniesStockCode?: InputMaybe<StringNullableListFilter>;
  approvedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  approvedBy?: InputMaybe<StringNullableWithAggregatesFilter>;
  company?: InputMaybe<StringNullableWithAggregatesFilter>;
  companyDescription?: InputMaybe<StringWithAggregatesFilter>;
  companyWebsite?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  createdBy?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  relatedCompanies?: InputMaybe<StringNullableListFilter>;
  relatedCompaniesStockCode?: InputMaybe<StringNullableListFilter>;
};

export type FeedbackDataUncheckedCreateInput = {
  advisedRelatedCompanies?: InputMaybe<Array<Scalars['String']>>;
  advisedRelatedCompaniesStockCode?: InputMaybe<Array<Scalars['String']>>;
  aiRelatedCompanies?: InputMaybe<Array<Scalars['String']>>;
  aiRelatedCompaniesStockCode?: InputMaybe<Array<Scalars['String']>>;
  approvedAt?: InputMaybe<Scalars['DateTime']>;
  approvedBy?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  companyDescription: Scalars['String'];
  companyWebsite?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  relatedCompanies?: InputMaybe<Array<Scalars['String']>>;
  relatedCompaniesStockCode?: InputMaybe<Array<Scalars['String']>>;
};

export type FeedbackDataUncheckedUpdateInput = {
  advisedRelatedCompanies?: InputMaybe<Array<Scalars['String']>>;
  advisedRelatedCompaniesStockCode?: InputMaybe<Array<Scalars['String']>>;
  aiRelatedCompanies?: InputMaybe<Array<Scalars['String']>>;
  aiRelatedCompaniesStockCode?: InputMaybe<Array<Scalars['String']>>;
  approvedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  approvedBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<StringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  relatedCompanies?: InputMaybe<Array<Scalars['String']>>;
  relatedCompaniesStockCode?: InputMaybe<Array<Scalars['String']>>;
};

export type FeedbackDataUncheckedUpdateManyInput = {
  advisedRelatedCompanies?: InputMaybe<Array<Scalars['String']>>;
  advisedRelatedCompaniesStockCode?: InputMaybe<Array<Scalars['String']>>;
  aiRelatedCompanies?: InputMaybe<Array<Scalars['String']>>;
  aiRelatedCompaniesStockCode?: InputMaybe<Array<Scalars['String']>>;
  approvedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  approvedBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<StringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  relatedCompanies?: InputMaybe<Array<Scalars['String']>>;
  relatedCompaniesStockCode?: InputMaybe<Array<Scalars['String']>>;
};

export type FeedbackDataUpdateInput = {
  advisedRelatedCompanies?: InputMaybe<Array<Scalars['String']>>;
  advisedRelatedCompaniesStockCode?: InputMaybe<Array<Scalars['String']>>;
  aiRelatedCompanies?: InputMaybe<Array<Scalars['String']>>;
  aiRelatedCompaniesStockCode?: InputMaybe<Array<Scalars['String']>>;
  approvedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  approvedBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<StringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  relatedCompanies?: InputMaybe<Array<Scalars['String']>>;
  relatedCompaniesStockCode?: InputMaybe<Array<Scalars['String']>>;
};

export type FeedbackDataUpdateManyMutationInput = {
  advisedRelatedCompanies?: InputMaybe<Array<Scalars['String']>>;
  advisedRelatedCompaniesStockCode?: InputMaybe<Array<Scalars['String']>>;
  aiRelatedCompanies?: InputMaybe<Array<Scalars['String']>>;
  aiRelatedCompaniesStockCode?: InputMaybe<Array<Scalars['String']>>;
  approvedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  approvedBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<StringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  relatedCompanies?: InputMaybe<Array<Scalars['String']>>;
  relatedCompaniesStockCode?: InputMaybe<Array<Scalars['String']>>;
};

export type FeedbackDataUpdateadvisedRelatedCompaniesInput = {
  push?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type FeedbackDataUpdateadvisedRelatedCompaniesStockCodeInput = {
  push?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type FeedbackDataUpdateaiRelatedCompaniesInput = {
  push?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type FeedbackDataUpdateaiRelatedCompaniesStockCodeInput = {
  push?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type FeedbackDataUpdaterelatedCompaniesInput = {
  push?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type FeedbackDataUpdaterelatedCompaniesStockCodeInput = {
  push?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type FeedbackDataWhereInput = {
  AND?: InputMaybe<Array<FeedbackDataWhereInput>>;
  NOT?: InputMaybe<Array<FeedbackDataWhereInput>>;
  OR?: InputMaybe<Array<FeedbackDataWhereInput>>;
  advisedRelatedCompanies?: InputMaybe<StringNullableListFilter>;
  advisedRelatedCompaniesStockCode?: InputMaybe<StringNullableListFilter>;
  aiRelatedCompanies?: InputMaybe<StringNullableListFilter>;
  aiRelatedCompaniesStockCode?: InputMaybe<StringNullableListFilter>;
  approvedAt?: InputMaybe<DateTimeNullableFilter>;
  approvedBy?: InputMaybe<StringNullableFilter>;
  company?: InputMaybe<StringNullableFilter>;
  companyDescription?: InputMaybe<StringFilter>;
  companyWebsite?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  relatedCompanies?: InputMaybe<StringNullableListFilter>;
  relatedCompaniesStockCode?: InputMaybe<StringNullableListFilter>;
};

export type FeedbackDataWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type FineTuneRequest = {
  _count: FineTuneRequestCountOutputType;
  batchSize?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  learningRateMultiplier?: Maybe<Scalars['Float']>;
  model?: Maybe<Scalars['String']>;
  nEpochs?: Maybe<Scalars['Int']>;
  suffix?: Maybe<Scalars['String']>;
  trainingData: Array<TrainingData>;
  uploadedTrainingFileId?: Maybe<Scalars['String']>;
};


export type FineTuneRequestTrainingDataArgs = {
  cursor?: InputMaybe<TrainingDataWhereUniqueInput>;
  distinct?: InputMaybe<TrainingDataScalarFieldEnum>;
  orderBy?: InputMaybe<TrainingDataOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TrainingDataWhereInput>;
};

export type FineTuneRequestAvgAggregateOutputType = {
  batchSize?: Maybe<Scalars['Float']>;
  learningRateMultiplier?: Maybe<Scalars['Float']>;
  nEpochs?: Maybe<Scalars['Float']>;
};

export type FineTuneRequestAvgOrderByAggregateInput = {
  batchSize?: InputMaybe<SortOrder>;
  learningRateMultiplier?: InputMaybe<SortOrder>;
  nEpochs?: InputMaybe<SortOrder>;
};

export type FineTuneRequestCountAggregateOutputType = {
  _all: Scalars['Int'];
  batchSize: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  learningRateMultiplier: Scalars['Int'];
  model: Scalars['Int'];
  nEpochs: Scalars['Int'];
  suffix: Scalars['Int'];
  uploadedTrainingFileId: Scalars['Int'];
};

export type FineTuneRequestCountOrderByAggregateInput = {
  batchSize?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  learningRateMultiplier?: InputMaybe<SortOrder>;
  model?: InputMaybe<SortOrder>;
  nEpochs?: InputMaybe<SortOrder>;
  suffix?: InputMaybe<SortOrder>;
  uploadedTrainingFileId?: InputMaybe<SortOrder>;
};

export type FineTuneRequestCountOutputType = {
  trainingData: Scalars['Int'];
};

export type FineTuneRequestCreateInput = {
  batchSize?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  learningRateMultiplier?: InputMaybe<Scalars['Float']>;
  model?: InputMaybe<Scalars['String']>;
  nEpochs?: InputMaybe<Scalars['Int']>;
  suffix?: InputMaybe<Scalars['String']>;
  trainingData?: InputMaybe<TrainingDataCreateNestedManyWithoutFineTuneRequestInput>;
  uploadedTrainingFileId?: InputMaybe<Scalars['String']>;
};

export type FineTuneRequestCreateManyInput = {
  batchSize?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  learningRateMultiplier?: InputMaybe<Scalars['Float']>;
  model?: InputMaybe<Scalars['String']>;
  nEpochs?: InputMaybe<Scalars['Int']>;
  suffix?: InputMaybe<Scalars['String']>;
  uploadedTrainingFileId?: InputMaybe<Scalars['String']>;
};

export type FineTuneRequestCreateNestedOneWithoutTrainingDataInput = {
  connect?: InputMaybe<FineTuneRequestWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FineTuneRequestCreateOrConnectWithoutTrainingDataInput>;
  create?: InputMaybe<FineTuneRequestUncheckedCreateWithoutTrainingDataInput>;
};

export type FineTuneRequestCreateOrConnectWithoutTrainingDataInput = {
  create: FineTuneRequestUncheckedCreateWithoutTrainingDataInput;
  where: FineTuneRequestWhereUniqueInput;
};

export type FineTuneRequestCreateWithoutTrainingDataInput = {
  batchSize?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  learningRateMultiplier?: InputMaybe<Scalars['Float']>;
  model?: InputMaybe<Scalars['String']>;
  nEpochs?: InputMaybe<Scalars['Int']>;
  suffix?: InputMaybe<Scalars['String']>;
  uploadedTrainingFileId?: InputMaybe<Scalars['String']>;
};

export type FineTuneRequestMaxAggregateOutputType = {
  batchSize?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  learningRateMultiplier?: Maybe<Scalars['Float']>;
  model?: Maybe<Scalars['String']>;
  nEpochs?: Maybe<Scalars['Int']>;
  suffix?: Maybe<Scalars['String']>;
  uploadedTrainingFileId?: Maybe<Scalars['String']>;
};

export type FineTuneRequestMaxOrderByAggregateInput = {
  batchSize?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  learningRateMultiplier?: InputMaybe<SortOrder>;
  model?: InputMaybe<SortOrder>;
  nEpochs?: InputMaybe<SortOrder>;
  suffix?: InputMaybe<SortOrder>;
  uploadedTrainingFileId?: InputMaybe<SortOrder>;
};

export type FineTuneRequestMinAggregateOutputType = {
  batchSize?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  learningRateMultiplier?: Maybe<Scalars['Float']>;
  model?: Maybe<Scalars['String']>;
  nEpochs?: Maybe<Scalars['Int']>;
  suffix?: Maybe<Scalars['String']>;
  uploadedTrainingFileId?: Maybe<Scalars['String']>;
};

export type FineTuneRequestMinOrderByAggregateInput = {
  batchSize?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  learningRateMultiplier?: InputMaybe<SortOrder>;
  model?: InputMaybe<SortOrder>;
  nEpochs?: InputMaybe<SortOrder>;
  suffix?: InputMaybe<SortOrder>;
  uploadedTrainingFileId?: InputMaybe<SortOrder>;
};

export type FineTuneRequestOrderByWithAggregationInput = {
  _avg?: InputMaybe<FineTuneRequestAvgOrderByAggregateInput>;
  _count?: InputMaybe<FineTuneRequestCountOrderByAggregateInput>;
  _max?: InputMaybe<FineTuneRequestMaxOrderByAggregateInput>;
  _min?: InputMaybe<FineTuneRequestMinOrderByAggregateInput>;
  _sum?: InputMaybe<FineTuneRequestSumOrderByAggregateInput>;
  batchSize?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  learningRateMultiplier?: InputMaybe<SortOrder>;
  model?: InputMaybe<SortOrder>;
  nEpochs?: InputMaybe<SortOrder>;
  suffix?: InputMaybe<SortOrder>;
  uploadedTrainingFileId?: InputMaybe<SortOrder>;
};

export type FineTuneRequestOrderByWithRelationInput = {
  batchSize?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  learningRateMultiplier?: InputMaybe<SortOrder>;
  model?: InputMaybe<SortOrder>;
  nEpochs?: InputMaybe<SortOrder>;
  suffix?: InputMaybe<SortOrder>;
  trainingData?: InputMaybe<TrainingDataOrderByRelationAggregateInput>;
  uploadedTrainingFileId?: InputMaybe<SortOrder>;
};

export type FineTuneRequestRelationFilter = {
  is?: InputMaybe<FineTuneRequestWhereInput>;
  isNot?: InputMaybe<FineTuneRequestWhereInput>;
};

export enum FineTuneRequestScalarFieldEnum {
  BatchSize = 'batchSize',
  CreatedAt = 'createdAt',
  Id = 'id',
  LearningRateMultiplier = 'learningRateMultiplier',
  Model = 'model',
  NEpochs = 'nEpochs',
  Suffix = 'suffix',
  UploadedTrainingFileId = 'uploadedTrainingFileId'
}

export type FineTuneRequestScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<FineTuneRequestScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<FineTuneRequestScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<FineTuneRequestScalarWhereWithAggregatesInput>>;
  batchSize?: InputMaybe<IntNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  learningRateMultiplier?: InputMaybe<FloatNullableWithAggregatesFilter>;
  model?: InputMaybe<StringNullableWithAggregatesFilter>;
  nEpochs?: InputMaybe<IntNullableWithAggregatesFilter>;
  suffix?: InputMaybe<StringNullableWithAggregatesFilter>;
  uploadedTrainingFileId?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type FineTuneRequestSumAggregateOutputType = {
  batchSize?: Maybe<Scalars['Int']>;
  learningRateMultiplier?: Maybe<Scalars['Float']>;
  nEpochs?: Maybe<Scalars['Int']>;
};

export type FineTuneRequestSumOrderByAggregateInput = {
  batchSize?: InputMaybe<SortOrder>;
  learningRateMultiplier?: InputMaybe<SortOrder>;
  nEpochs?: InputMaybe<SortOrder>;
};

export type FineTuneRequestUncheckedCreateInput = {
  batchSize?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  learningRateMultiplier?: InputMaybe<Scalars['Float']>;
  model?: InputMaybe<Scalars['String']>;
  nEpochs?: InputMaybe<Scalars['Int']>;
  suffix?: InputMaybe<Scalars['String']>;
  trainingData?: InputMaybe<TrainingDataUncheckedCreateNestedManyWithoutFineTuneRequestInput>;
  uploadedTrainingFileId?: InputMaybe<Scalars['String']>;
};

export type FineTuneRequestUncheckedCreateWithoutTrainingDataInput = {
  batchSize?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  learningRateMultiplier?: InputMaybe<Scalars['Float']>;
  model?: InputMaybe<Scalars['String']>;
  nEpochs?: InputMaybe<Scalars['Int']>;
  suffix?: InputMaybe<Scalars['String']>;
  uploadedTrainingFileId?: InputMaybe<Scalars['String']>;
};

export type FineTuneRequestUncheckedUpdateInput = {
  batchSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  learningRateMultiplier?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  model?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  nEpochs?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  suffix?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  trainingData?: InputMaybe<TrainingDataUncheckedUpdateManyWithoutFineTuneRequestNestedInput>;
  uploadedTrainingFileId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type FineTuneRequestUncheckedUpdateManyInput = {
  batchSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  learningRateMultiplier?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  model?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  nEpochs?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  suffix?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uploadedTrainingFileId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type FineTuneRequestUncheckedUpdateWithoutTrainingDataInput = {
  batchSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  learningRateMultiplier?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  model?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  nEpochs?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  suffix?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uploadedTrainingFileId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type FineTuneRequestUpdateInput = {
  batchSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  learningRateMultiplier?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  model?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  nEpochs?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  suffix?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  trainingData?: InputMaybe<TrainingDataUpdateManyWithoutFineTuneRequestNestedInput>;
  uploadedTrainingFileId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type FineTuneRequestUpdateManyMutationInput = {
  batchSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  learningRateMultiplier?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  model?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  nEpochs?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  suffix?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uploadedTrainingFileId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type FineTuneRequestUpdateOneWithoutTrainingDataNestedInput = {
  connect?: InputMaybe<FineTuneRequestWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FineTuneRequestCreateOrConnectWithoutTrainingDataInput>;
  create?: InputMaybe<FineTuneRequestUncheckedCreateWithoutTrainingDataInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<FineTuneRequestUncheckedUpdateWithoutTrainingDataInput>;
  upsert?: InputMaybe<FineTuneRequestUpsertWithoutTrainingDataInput>;
};

export type FineTuneRequestUpdateWithoutTrainingDataInput = {
  batchSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  learningRateMultiplier?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  model?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  nEpochs?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  suffix?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uploadedTrainingFileId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type FineTuneRequestUpsertWithoutTrainingDataInput = {
  create: FineTuneRequestUncheckedCreateWithoutTrainingDataInput;
  update: FineTuneRequestUncheckedUpdateWithoutTrainingDataInput;
};

export type FineTuneRequestWhereInput = {
  AND?: InputMaybe<Array<FineTuneRequestWhereInput>>;
  NOT?: InputMaybe<Array<FineTuneRequestWhereInput>>;
  OR?: InputMaybe<Array<FineTuneRequestWhereInput>>;
  batchSize?: InputMaybe<IntNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  learningRateMultiplier?: InputMaybe<FloatNullableFilter>;
  model?: InputMaybe<StringNullableFilter>;
  nEpochs?: InputMaybe<IntNullableFilter>;
  suffix?: InputMaybe<StringNullableFilter>;
  trainingData?: InputMaybe<TrainingDataListRelationFilter>;
  uploadedTrainingFileId?: InputMaybe<StringNullableFilter>;
};

export type FineTuneRequestWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type FloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type FloatNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedFloatNullableFilter>;
  _min?: InputMaybe<NestedFloatNullableFilter>;
  _sum?: InputMaybe<NestedFloatNullableFilter>;
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedIntNullableFilter>;
  _min?: InputMaybe<NestedIntNullableFilter>;
  _sum?: InputMaybe<NestedIntNullableFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export enum JsonNullValueFilter {
  AnyNull = 'AnyNull',
  DbNull = 'DbNull',
  JsonNull = 'JsonNull'
}

export type JsonNullableFilter = {
  array_contains?: InputMaybe<Scalars['Json']>;
  array_ends_with?: InputMaybe<Scalars['Json']>;
  array_starts_with?: InputMaybe<Scalars['Json']>;
  equals?: InputMaybe<Scalars['Json']>;
  gt?: InputMaybe<Scalars['Json']>;
  gte?: InputMaybe<Scalars['Json']>;
  lt?: InputMaybe<Scalars['Json']>;
  lte?: InputMaybe<Scalars['Json']>;
  not?: InputMaybe<Scalars['Json']>;
  path?: InputMaybe<Array<Scalars['String']>>;
  string_contains?: InputMaybe<Scalars['String']>;
  string_ends_with?: InputMaybe<Scalars['String']>;
  string_starts_with?: InputMaybe<Scalars['String']>;
};

export type JsonNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedJsonNullableFilter>;
  _min?: InputMaybe<NestedJsonNullableFilter>;
  array_contains?: InputMaybe<Scalars['Json']>;
  array_ends_with?: InputMaybe<Scalars['Json']>;
  array_starts_with?: InputMaybe<Scalars['Json']>;
  equals?: InputMaybe<Scalars['Json']>;
  gt?: InputMaybe<Scalars['Json']>;
  gte?: InputMaybe<Scalars['Json']>;
  lt?: InputMaybe<Scalars['Json']>;
  lte?: InputMaybe<Scalars['Json']>;
  not?: InputMaybe<Scalars['Json']>;
  path?: InputMaybe<Array<Scalars['String']>>;
  string_contains?: InputMaybe<Scalars['String']>;
  string_ends_with?: InputMaybe<Scalars['String']>;
  string_starts_with?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  createFeedbackData?: Maybe<FeedbackData>;
  createOneCompany: Company;
  createOneComparableAiSearch: ComparableAiSearch;
  createOneFeedbackData: FeedbackData;
  createOneFineTuneRequest: FineTuneRequest;
  createOneRelatedCompanyInTrainingData: RelatedCompanyInTrainingData;
  createOneSimpleFineTuneRequest: SimpleFineTuneRequest;
  createOneSimpleTrainingData: SimpleTrainingData;
  createOneTrainingData: TrainingData;
  createSimpleTrainingData?: Maybe<SimpleTrainingData>;
  createTrainingData?: Maybe<TrainingData>;
  deleteManyCompany?: Maybe<BatchPayload>;
  deleteManyComparableAiSearch?: Maybe<BatchPayload>;
  deleteManyFeedbackData?: Maybe<BatchPayload>;
  deleteManyFineTuneRequest?: Maybe<BatchPayload>;
  deleteManyRelatedCompanyInTrainingData?: Maybe<BatchPayload>;
  deleteManySimpleFineTuneRequest?: Maybe<BatchPayload>;
  deleteManySimpleTrainingData?: Maybe<BatchPayload>;
  deleteManyTrainingData?: Maybe<BatchPayload>;
  deleteOneCompany?: Maybe<Company>;
  deleteOneComparableAiSearch?: Maybe<ComparableAiSearch>;
  deleteOneFeedbackData?: Maybe<FeedbackData>;
  deleteOneFineTuneRequest?: Maybe<FineTuneRequest>;
  deleteOneRelatedCompanyInTrainingData?: Maybe<RelatedCompanyInTrainingData>;
  deleteOneSimpleFineTuneRequest?: Maybe<SimpleFineTuneRequest>;
  deleteOneSimpleTrainingData?: Maybe<SimpleTrainingData>;
  deleteOneTrainingData?: Maybe<TrainingData>;
  updateManyCompany?: Maybe<BatchPayload>;
  updateManyComparableAiSearch?: Maybe<BatchPayload>;
  updateManyFeedbackData?: Maybe<BatchPayload>;
  updateManyFineTuneRequest?: Maybe<BatchPayload>;
  updateManyRelatedCompanyInTrainingData?: Maybe<BatchPayload>;
  updateManySimpleFineTuneRequest?: Maybe<BatchPayload>;
  updateManySimpleTrainingData?: Maybe<BatchPayload>;
  updateManyTrainingData?: Maybe<BatchPayload>;
  updateOneCompany: Company;
  updateOneComparableAiSearch: ComparableAiSearch;
  updateOneFeedbackData: FeedbackData;
  updateOneFineTuneRequest: FineTuneRequest;
  updateOneRelatedCompanyInTrainingData: RelatedCompanyInTrainingData;
  updateOneSimpleFineTuneRequest: SimpleFineTuneRequest;
  updateOneSimpleTrainingData: SimpleTrainingData;
  updateOneTrainingData: TrainingData;
  upsertOneCompany?: Maybe<Company>;
  upsertOneComparableAiSearch?: Maybe<ComparableAiSearch>;
  upsertOneFeedbackData?: Maybe<FeedbackData>;
  upsertOneFineTuneRequest?: Maybe<FineTuneRequest>;
  upsertOneRelatedCompanyInTrainingData?: Maybe<RelatedCompanyInTrainingData>;
  upsertOneSimpleFineTuneRequest?: Maybe<SimpleFineTuneRequest>;
  upsertOneSimpleTrainingData?: Maybe<SimpleTrainingData>;
  upsertOneTrainingData?: Maybe<TrainingData>;
};


export type MutationCreateFeedbackDataArgs = {
  aiRelatedCompanies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  aiRelatedCompaniesStockCode?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  company: Scalars['String'];
  companyDescription: Scalars['String'];
  companyWebsite?: InputMaybe<Scalars['String']>;
  relatedCompanies: Array<InputMaybe<Scalars['String']>>;
  relatedCompaniesStockCode?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationCreateOneCompanyArgs = {
  data: CompanyCreateInput;
};


export type MutationCreateOneComparableAiSearchArgs = {
  data: ComparableAiSearchCreateInput;
};


export type MutationCreateOneFeedbackDataArgs = {
  data: FeedbackDataCreateInput;
};


export type MutationCreateOneFineTuneRequestArgs = {
  data: FineTuneRequestCreateInput;
};


export type MutationCreateOneRelatedCompanyInTrainingDataArgs = {
  data: RelatedCompanyInTrainingDataCreateInput;
};


export type MutationCreateOneSimpleFineTuneRequestArgs = {
  data: SimpleFineTuneRequestCreateInput;
};


export type MutationCreateOneSimpleTrainingDataArgs = {
  data: SimpleTrainingDataCreateInput;
};


export type MutationCreateOneTrainingDataArgs = {
  data: TrainingDataCreateInput;
};


export type MutationCreateSimpleTrainingDataArgs = {
  company: Scalars['String'];
  relatedCompaniesName: Array<InputMaybe<Scalars['String']>>;
  relatedCompaniesStockCode?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationCreateTrainingDataArgs = {
  company: Scalars['String'];
  relatedCompaniesId: Array<InputMaybe<Scalars['String']>>;
};


export type MutationDeleteManyCompanyArgs = {
  where?: InputMaybe<CompanyWhereInput>;
};


export type MutationDeleteManyComparableAiSearchArgs = {
  where?: InputMaybe<ComparableAiSearchWhereInput>;
};


export type MutationDeleteManyFeedbackDataArgs = {
  where?: InputMaybe<FeedbackDataWhereInput>;
};


export type MutationDeleteManyFineTuneRequestArgs = {
  where?: InputMaybe<FineTuneRequestWhereInput>;
};


export type MutationDeleteManyRelatedCompanyInTrainingDataArgs = {
  where?: InputMaybe<RelatedCompanyInTrainingDataWhereInput>;
};


export type MutationDeleteManySimpleFineTuneRequestArgs = {
  where?: InputMaybe<SimpleFineTuneRequestWhereInput>;
};


export type MutationDeleteManySimpleTrainingDataArgs = {
  where?: InputMaybe<SimpleTrainingDataWhereInput>;
};


export type MutationDeleteManyTrainingDataArgs = {
  where?: InputMaybe<TrainingDataWhereInput>;
};


export type MutationDeleteOneCompanyArgs = {
  where: CompanyWhereUniqueInput;
};


export type MutationDeleteOneComparableAiSearchArgs = {
  where: ComparableAiSearchWhereUniqueInput;
};


export type MutationDeleteOneFeedbackDataArgs = {
  where: FeedbackDataWhereUniqueInput;
};


export type MutationDeleteOneFineTuneRequestArgs = {
  where: FineTuneRequestWhereUniqueInput;
};


export type MutationDeleteOneRelatedCompanyInTrainingDataArgs = {
  where: RelatedCompanyInTrainingDataWhereUniqueInput;
};


export type MutationDeleteOneSimpleFineTuneRequestArgs = {
  where: SimpleFineTuneRequestWhereUniqueInput;
};


export type MutationDeleteOneSimpleTrainingDataArgs = {
  where: SimpleTrainingDataWhereUniqueInput;
};


export type MutationDeleteOneTrainingDataArgs = {
  where: TrainingDataWhereUniqueInput;
};


export type MutationUpdateManyCompanyArgs = {
  data: CompanyUpdateManyMutationInput;
  where?: InputMaybe<CompanyWhereInput>;
};


export type MutationUpdateManyComparableAiSearchArgs = {
  data: ComparableAiSearchUpdateManyMutationInput;
  where?: InputMaybe<ComparableAiSearchWhereInput>;
};


export type MutationUpdateManyFeedbackDataArgs = {
  data: FeedbackDataUpdateManyMutationInput;
  where?: InputMaybe<FeedbackDataWhereInput>;
};


export type MutationUpdateManyFineTuneRequestArgs = {
  data: FineTuneRequestUpdateManyMutationInput;
  where?: InputMaybe<FineTuneRequestWhereInput>;
};


export type MutationUpdateManyRelatedCompanyInTrainingDataArgs = {
  data: RelatedCompanyInTrainingDataUpdateManyMutationInput;
  where?: InputMaybe<RelatedCompanyInTrainingDataWhereInput>;
};


export type MutationUpdateManySimpleFineTuneRequestArgs = {
  data: SimpleFineTuneRequestUpdateManyMutationInput;
  where?: InputMaybe<SimpleFineTuneRequestWhereInput>;
};


export type MutationUpdateManySimpleTrainingDataArgs = {
  data: SimpleTrainingDataUpdateManyMutationInput;
  where?: InputMaybe<SimpleTrainingDataWhereInput>;
};


export type MutationUpdateManyTrainingDataArgs = {
  data: TrainingDataUpdateManyMutationInput;
  where?: InputMaybe<TrainingDataWhereInput>;
};


export type MutationUpdateOneCompanyArgs = {
  data: CompanyUpdateInput;
  where: CompanyWhereUniqueInput;
};


export type MutationUpdateOneComparableAiSearchArgs = {
  data: ComparableAiSearchUpdateInput;
  where: ComparableAiSearchWhereUniqueInput;
};


export type MutationUpdateOneFeedbackDataArgs = {
  data: FeedbackDataUpdateInput;
  where: FeedbackDataWhereUniqueInput;
};


export type MutationUpdateOneFineTuneRequestArgs = {
  data: FineTuneRequestUpdateInput;
  where: FineTuneRequestWhereUniqueInput;
};


export type MutationUpdateOneRelatedCompanyInTrainingDataArgs = {
  data: RelatedCompanyInTrainingDataUpdateInput;
  where: RelatedCompanyInTrainingDataWhereUniqueInput;
};


export type MutationUpdateOneSimpleFineTuneRequestArgs = {
  data: SimpleFineTuneRequestUpdateInput;
  where: SimpleFineTuneRequestWhereUniqueInput;
};


export type MutationUpdateOneSimpleTrainingDataArgs = {
  data: SimpleTrainingDataUpdateInput;
  where: SimpleTrainingDataWhereUniqueInput;
};


export type MutationUpdateOneTrainingDataArgs = {
  data: TrainingDataUpdateInput;
  where: TrainingDataWhereUniqueInput;
};


export type MutationUpsertOneCompanyArgs = {
  create: CompanyCreateInput;
  update: CompanyUpdateInput;
  where: CompanyWhereUniqueInput;
};


export type MutationUpsertOneComparableAiSearchArgs = {
  create: ComparableAiSearchCreateInput;
  update: ComparableAiSearchUpdateInput;
  where: ComparableAiSearchWhereUniqueInput;
};


export type MutationUpsertOneFeedbackDataArgs = {
  create: FeedbackDataCreateInput;
  update: FeedbackDataUpdateInput;
  where: FeedbackDataWhereUniqueInput;
};


export type MutationUpsertOneFineTuneRequestArgs = {
  create: FineTuneRequestCreateInput;
  update: FineTuneRequestUpdateInput;
  where: FineTuneRequestWhereUniqueInput;
};


export type MutationUpsertOneRelatedCompanyInTrainingDataArgs = {
  create: RelatedCompanyInTrainingDataCreateInput;
  update: RelatedCompanyInTrainingDataUpdateInput;
  where: RelatedCompanyInTrainingDataWhereUniqueInput;
};


export type MutationUpsertOneSimpleFineTuneRequestArgs = {
  create: SimpleFineTuneRequestCreateInput;
  update: SimpleFineTuneRequestUpdateInput;
  where: SimpleFineTuneRequestWhereUniqueInput;
};


export type MutationUpsertOneSimpleTrainingDataArgs = {
  create: SimpleTrainingDataCreateInput;
  update: SimpleTrainingDataUpdateInput;
  where: SimpleTrainingDataWhereUniqueInput;
};


export type MutationUpsertOneTrainingDataArgs = {
  create: TrainingDataCreateInput;
  update: TrainingDataUpdateInput;
  where: TrainingDataWhereUniqueInput;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDateTimeNullableFilter>;
  _min?: InputMaybe<NestedDateTimeNullableFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedFloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedFloatNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedFloatNullableFilter>;
  _min?: InputMaybe<NestedFloatNullableFilter>;
  _sum?: InputMaybe<NestedFloatNullableFilter>;
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedIntNullableFilter>;
  _min?: InputMaybe<NestedIntNullableFilter>;
  _sum?: InputMaybe<NestedIntNullableFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedJsonNullableFilter = {
  array_contains?: InputMaybe<Scalars['Json']>;
  array_ends_with?: InputMaybe<Scalars['Json']>;
  array_starts_with?: InputMaybe<Scalars['Json']>;
  equals?: InputMaybe<Scalars['Json']>;
  gt?: InputMaybe<Scalars['Json']>;
  gte?: InputMaybe<Scalars['Json']>;
  lt?: InputMaybe<Scalars['Json']>;
  lte?: InputMaybe<Scalars['Json']>;
  not?: InputMaybe<Scalars['Json']>;
  path?: InputMaybe<Array<Scalars['String']>>;
  string_contains?: InputMaybe<Scalars['String']>;
  string_ends_with?: InputMaybe<Scalars['String']>;
  string_starts_with?: InputMaybe<Scalars['String']>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']>;
};

export type NullableFloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Float']>;
  divide?: InputMaybe<Scalars['Float']>;
  increment?: InputMaybe<Scalars['Float']>;
  multiply?: InputMaybe<Scalars['Float']>;
  set?: InputMaybe<Scalars['Float']>;
};

export type NullableIntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']>;
  divide?: InputMaybe<Scalars['Int']>;
  increment?: InputMaybe<Scalars['Int']>;
  multiply?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<Scalars['Int']>;
};

export enum NullableJsonNullValueInput {
  DbNull = 'DbNull',
  JsonNull = 'JsonNull'
}

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type Query = {
  aggregateCompany?: Maybe<AggregateCompany>;
  aggregateComparableAiSearch?: Maybe<AggregateComparableAiSearch>;
  aggregateFeedbackData?: Maybe<AggregateFeedbackData>;
  aggregateFineTuneRequest?: Maybe<AggregateFineTuneRequest>;
  aggregateRelatedCompanyInTrainingData?: Maybe<AggregateRelatedCompanyInTrainingData>;
  aggregateSimpleFineTuneRequest?: Maybe<AggregateSimpleFineTuneRequest>;
  aggregateSimpleTrainingData?: Maybe<AggregateSimpleTrainingData>;
  aggregateTrainingData?: Maybe<AggregateTrainingData>;
  findFirstCompany?: Maybe<Company>;
  findFirstComparableAiSearch?: Maybe<ComparableAiSearch>;
  findFirstFeedbackData?: Maybe<FeedbackData>;
  findFirstFineTuneRequest?: Maybe<FineTuneRequest>;
  findFirstRelatedCompanyInTrainingData?: Maybe<RelatedCompanyInTrainingData>;
  findFirstSimpleFineTuneRequest?: Maybe<SimpleFineTuneRequest>;
  findFirstSimpleTrainingData?: Maybe<SimpleTrainingData>;
  findFirstTrainingData?: Maybe<TrainingData>;
  findManyCompany?: Maybe<Array<Company>>;
  findManyCompanyCount: Scalars['Int'];
  findManyComparableAiSearch?: Maybe<Array<ComparableAiSearch>>;
  findManyComparableAiSearchCount: Scalars['Int'];
  findManyFeedbackData?: Maybe<Array<FeedbackData>>;
  findManyFeedbackDataCount: Scalars['Int'];
  findManyFineTuneRequest?: Maybe<Array<FineTuneRequest>>;
  findManyFineTuneRequestCount: Scalars['Int'];
  findManyRelatedCompanyInTrainingData?: Maybe<Array<RelatedCompanyInTrainingData>>;
  findManyRelatedCompanyInTrainingDataCount: Scalars['Int'];
  findManySimpleFineTuneRequest?: Maybe<Array<SimpleFineTuneRequest>>;
  findManySimpleFineTuneRequestCount: Scalars['Int'];
  findManySimpleTrainingData?: Maybe<Array<SimpleTrainingData>>;
  findManySimpleTrainingDataCount: Scalars['Int'];
  findManyTrainingData?: Maybe<Array<TrainingData>>;
  findManyTrainingDataCount: Scalars['Int'];
  findUniqueCompany?: Maybe<Company>;
  findUniqueComparableAiSearch?: Maybe<ComparableAiSearch>;
  findUniqueFeedbackData?: Maybe<FeedbackData>;
  findUniqueFineTuneRequest?: Maybe<FineTuneRequest>;
  findUniqueRelatedCompanyInTrainingData?: Maybe<RelatedCompanyInTrainingData>;
  findUniqueSimpleFineTuneRequest?: Maybe<SimpleFineTuneRequest>;
  findUniqueSimpleTrainingData?: Maybe<SimpleTrainingData>;
  findUniqueTrainingData?: Maybe<TrainingData>;
  getCompaniesDropdownOptions?: Maybe<Array<Company>>;
  getOpenAiModels?: Maybe<Array<Maybe<Scalars['String']>>>;
  searchRelatedCompanies?: Maybe<Array<Company>>;
};


export type QueryAggregateCompanyArgs = {
  cursor?: InputMaybe<CompanyWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<CompanyOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CompanyWhereInput>;
};


export type QueryAggregateComparableAiSearchArgs = {
  cursor?: InputMaybe<ComparableAiSearchWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<ComparableAiSearchOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ComparableAiSearchWhereInput>;
};


export type QueryAggregateFeedbackDataArgs = {
  cursor?: InputMaybe<FeedbackDataWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<FeedbackDataOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FeedbackDataWhereInput>;
};


export type QueryAggregateFineTuneRequestArgs = {
  cursor?: InputMaybe<FineTuneRequestWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<FineTuneRequestOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FineTuneRequestWhereInput>;
};


export type QueryAggregateRelatedCompanyInTrainingDataArgs = {
  cursor?: InputMaybe<RelatedCompanyInTrainingDataWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<RelatedCompanyInTrainingDataOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RelatedCompanyInTrainingDataWhereInput>;
};


export type QueryAggregateSimpleFineTuneRequestArgs = {
  cursor?: InputMaybe<SimpleFineTuneRequestWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<SimpleFineTuneRequestOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SimpleFineTuneRequestWhereInput>;
};


export type QueryAggregateSimpleTrainingDataArgs = {
  cursor?: InputMaybe<SimpleTrainingDataWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<SimpleTrainingDataOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SimpleTrainingDataWhereInput>;
};


export type QueryAggregateTrainingDataArgs = {
  cursor?: InputMaybe<TrainingDataWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<TrainingDataOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TrainingDataWhereInput>;
};


export type QueryFindFirstCompanyArgs = {
  cursor?: InputMaybe<CompanyWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<CompanyScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<CompanyOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CompanyWhereInput>;
};


export type QueryFindFirstComparableAiSearchArgs = {
  cursor?: InputMaybe<ComparableAiSearchWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ComparableAiSearchScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ComparableAiSearchOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ComparableAiSearchWhereInput>;
};


export type QueryFindFirstFeedbackDataArgs = {
  cursor?: InputMaybe<FeedbackDataWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<FeedbackDataScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<FeedbackDataOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FeedbackDataWhereInput>;
};


export type QueryFindFirstFineTuneRequestArgs = {
  cursor?: InputMaybe<FineTuneRequestWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<FineTuneRequestScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<FineTuneRequestOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FineTuneRequestWhereInput>;
};


export type QueryFindFirstRelatedCompanyInTrainingDataArgs = {
  cursor?: InputMaybe<RelatedCompanyInTrainingDataWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<RelatedCompanyInTrainingDataScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<RelatedCompanyInTrainingDataOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RelatedCompanyInTrainingDataWhereInput>;
};


export type QueryFindFirstSimpleFineTuneRequestArgs = {
  cursor?: InputMaybe<SimpleFineTuneRequestWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<SimpleFineTuneRequestScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<SimpleFineTuneRequestOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SimpleFineTuneRequestWhereInput>;
};


export type QueryFindFirstSimpleTrainingDataArgs = {
  cursor?: InputMaybe<SimpleTrainingDataWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<SimpleTrainingDataScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<SimpleTrainingDataOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SimpleTrainingDataWhereInput>;
};


export type QueryFindFirstTrainingDataArgs = {
  cursor?: InputMaybe<TrainingDataWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<TrainingDataScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<TrainingDataOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TrainingDataWhereInput>;
};


export type QueryFindManyCompanyArgs = {
  cursor?: InputMaybe<CompanyWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<CompanyScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<CompanyOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CompanyWhereInput>;
};


export type QueryFindManyCompanyCountArgs = {
  cursor?: InputMaybe<CompanyWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<CompanyScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<CompanyOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CompanyWhereInput>;
};


export type QueryFindManyComparableAiSearchArgs = {
  cursor?: InputMaybe<ComparableAiSearchWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ComparableAiSearchScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ComparableAiSearchOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ComparableAiSearchWhereInput>;
};


export type QueryFindManyComparableAiSearchCountArgs = {
  cursor?: InputMaybe<ComparableAiSearchWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ComparableAiSearchScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ComparableAiSearchOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ComparableAiSearchWhereInput>;
};


export type QueryFindManyFeedbackDataArgs = {
  cursor?: InputMaybe<FeedbackDataWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<FeedbackDataScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<FeedbackDataOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FeedbackDataWhereInput>;
};


export type QueryFindManyFeedbackDataCountArgs = {
  cursor?: InputMaybe<FeedbackDataWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<FeedbackDataScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<FeedbackDataOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FeedbackDataWhereInput>;
};


export type QueryFindManyFineTuneRequestArgs = {
  cursor?: InputMaybe<FineTuneRequestWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<FineTuneRequestScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<FineTuneRequestOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FineTuneRequestWhereInput>;
};


export type QueryFindManyFineTuneRequestCountArgs = {
  cursor?: InputMaybe<FineTuneRequestWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<FineTuneRequestScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<FineTuneRequestOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FineTuneRequestWhereInput>;
};


export type QueryFindManyRelatedCompanyInTrainingDataArgs = {
  cursor?: InputMaybe<RelatedCompanyInTrainingDataWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<RelatedCompanyInTrainingDataScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<RelatedCompanyInTrainingDataOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RelatedCompanyInTrainingDataWhereInput>;
};


export type QueryFindManyRelatedCompanyInTrainingDataCountArgs = {
  cursor?: InputMaybe<RelatedCompanyInTrainingDataWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<RelatedCompanyInTrainingDataScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<RelatedCompanyInTrainingDataOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RelatedCompanyInTrainingDataWhereInput>;
};


export type QueryFindManySimpleFineTuneRequestArgs = {
  cursor?: InputMaybe<SimpleFineTuneRequestWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<SimpleFineTuneRequestScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<SimpleFineTuneRequestOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SimpleFineTuneRequestWhereInput>;
};


export type QueryFindManySimpleFineTuneRequestCountArgs = {
  cursor?: InputMaybe<SimpleFineTuneRequestWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<SimpleFineTuneRequestScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<SimpleFineTuneRequestOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SimpleFineTuneRequestWhereInput>;
};


export type QueryFindManySimpleTrainingDataArgs = {
  cursor?: InputMaybe<SimpleTrainingDataWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<SimpleTrainingDataScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<SimpleTrainingDataOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SimpleTrainingDataWhereInput>;
};


export type QueryFindManySimpleTrainingDataCountArgs = {
  cursor?: InputMaybe<SimpleTrainingDataWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<SimpleTrainingDataScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<SimpleTrainingDataOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SimpleTrainingDataWhereInput>;
};


export type QueryFindManyTrainingDataArgs = {
  cursor?: InputMaybe<TrainingDataWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<TrainingDataScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<TrainingDataOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TrainingDataWhereInput>;
};


export type QueryFindManyTrainingDataCountArgs = {
  cursor?: InputMaybe<TrainingDataWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<TrainingDataScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<TrainingDataOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TrainingDataWhereInput>;
};


export type QueryFindUniqueCompanyArgs = {
  where: CompanyWhereUniqueInput;
};


export type QueryFindUniqueComparableAiSearchArgs = {
  where: ComparableAiSearchWhereUniqueInput;
};


export type QueryFindUniqueFeedbackDataArgs = {
  where: FeedbackDataWhereUniqueInput;
};


export type QueryFindUniqueFineTuneRequestArgs = {
  where: FineTuneRequestWhereUniqueInput;
};


export type QueryFindUniqueRelatedCompanyInTrainingDataArgs = {
  where: RelatedCompanyInTrainingDataWhereUniqueInput;
};


export type QueryFindUniqueSimpleFineTuneRequestArgs = {
  where: SimpleFineTuneRequestWhereUniqueInput;
};


export type QueryFindUniqueSimpleTrainingDataArgs = {
  where: SimpleTrainingDataWhereUniqueInput;
};


export type QueryFindUniqueTrainingDataArgs = {
  where: TrainingDataWhereUniqueInput;
};


export type QueryGetCompaniesDropdownOptionsArgs = {
  keyword?: InputMaybe<Scalars['String']>;
};


export type QuerySearchRelatedCompaniesArgs = {
  model?: InputMaybe<Scalars['String']>;
  searchString?: InputMaybe<Scalars['String']>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type RelatedCompanyInTrainingData = {
  TrainingData: TrainingData;
  TrainingDataId: Scalars['String'];
  id: Scalars['String'];
  relatedCompanies: Company;
  relatedCompaniesId: Scalars['String'];
};

export type RelatedCompanyInTrainingDataCountAggregateOutputType = {
  TrainingDataId: Scalars['Int'];
  _all: Scalars['Int'];
  id: Scalars['Int'];
  relatedCompaniesId: Scalars['Int'];
};

export type RelatedCompanyInTrainingDataCountOrderByAggregateInput = {
  TrainingDataId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  relatedCompaniesId?: InputMaybe<SortOrder>;
};

export type RelatedCompanyInTrainingDataCreateInput = {
  TrainingData: TrainingDataCreateNestedOneWithoutRelatedCompaniesInput;
  id?: InputMaybe<Scalars['String']>;
  relatedCompanies: CompanyCreateNestedOneWithoutTrainedRelatedCompaniesInput;
};

export type RelatedCompanyInTrainingDataCreateManyInput = {
  TrainingDataId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  relatedCompaniesId: Scalars['String'];
};

export type RelatedCompanyInTrainingDataCreateManyRelatedCompaniesInput = {
  TrainingDataId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
};

export type RelatedCompanyInTrainingDataCreateManyRelatedCompaniesInputEnvelope = {
  data: Array<RelatedCompanyInTrainingDataCreateManyRelatedCompaniesInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type RelatedCompanyInTrainingDataCreateManyTrainingDataInput = {
  id?: InputMaybe<Scalars['String']>;
  relatedCompaniesId: Scalars['String'];
};

export type RelatedCompanyInTrainingDataCreateManyTrainingDataInputEnvelope = {
  data: Array<RelatedCompanyInTrainingDataCreateManyTrainingDataInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type RelatedCompanyInTrainingDataCreateNestedManyWithoutRelatedCompaniesInput = {
  connect?: InputMaybe<Array<RelatedCompanyInTrainingDataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RelatedCompanyInTrainingDataCreateOrConnectWithoutRelatedCompaniesInput>>;
  create?: InputMaybe<Array<RelatedCompanyInTrainingDataCreateWithoutRelatedCompaniesInput>>;
  createMany?: InputMaybe<RelatedCompanyInTrainingDataCreateManyRelatedCompaniesInputEnvelope>;
};

export type RelatedCompanyInTrainingDataCreateNestedManyWithoutTrainingDataInput = {
  connect?: InputMaybe<Array<RelatedCompanyInTrainingDataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RelatedCompanyInTrainingDataCreateOrConnectWithoutTrainingDataInput>>;
  create?: InputMaybe<Array<RelatedCompanyInTrainingDataCreateWithoutTrainingDataInput>>;
  createMany?: InputMaybe<RelatedCompanyInTrainingDataCreateManyTrainingDataInputEnvelope>;
};

export type RelatedCompanyInTrainingDataCreateOrConnectWithoutRelatedCompaniesInput = {
  create: RelatedCompanyInTrainingDataUncheckedCreateWithoutRelatedCompaniesInput;
  where: RelatedCompanyInTrainingDataWhereUniqueInput;
};

export type RelatedCompanyInTrainingDataCreateOrConnectWithoutTrainingDataInput = {
  create: RelatedCompanyInTrainingDataUncheckedCreateWithoutTrainingDataInput;
  where: RelatedCompanyInTrainingDataWhereUniqueInput;
};

export type RelatedCompanyInTrainingDataCreateWithoutRelatedCompaniesInput = {
  TrainingData: TrainingDataCreateNestedOneWithoutRelatedCompaniesInput;
  id?: InputMaybe<Scalars['String']>;
};

export type RelatedCompanyInTrainingDataCreateWithoutTrainingDataInput = {
  id?: InputMaybe<Scalars['String']>;
  relatedCompanies: CompanyCreateNestedOneWithoutTrainedRelatedCompaniesInput;
};

export type RelatedCompanyInTrainingDataListRelationFilter = {
  every?: InputMaybe<RelatedCompanyInTrainingDataWhereInput>;
  none?: InputMaybe<RelatedCompanyInTrainingDataWhereInput>;
  some?: InputMaybe<RelatedCompanyInTrainingDataWhereInput>;
};

export type RelatedCompanyInTrainingDataMaxAggregateOutputType = {
  TrainingDataId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  relatedCompaniesId?: Maybe<Scalars['String']>;
};

export type RelatedCompanyInTrainingDataMaxOrderByAggregateInput = {
  TrainingDataId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  relatedCompaniesId?: InputMaybe<SortOrder>;
};

export type RelatedCompanyInTrainingDataMinAggregateOutputType = {
  TrainingDataId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  relatedCompaniesId?: Maybe<Scalars['String']>;
};

export type RelatedCompanyInTrainingDataMinOrderByAggregateInput = {
  TrainingDataId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  relatedCompaniesId?: InputMaybe<SortOrder>;
};

export type RelatedCompanyInTrainingDataOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type RelatedCompanyInTrainingDataOrderByWithAggregationInput = {
  TrainingDataId?: InputMaybe<SortOrder>;
  _count?: InputMaybe<RelatedCompanyInTrainingDataCountOrderByAggregateInput>;
  _max?: InputMaybe<RelatedCompanyInTrainingDataMaxOrderByAggregateInput>;
  _min?: InputMaybe<RelatedCompanyInTrainingDataMinOrderByAggregateInput>;
  id?: InputMaybe<SortOrder>;
  relatedCompaniesId?: InputMaybe<SortOrder>;
};

export type RelatedCompanyInTrainingDataOrderByWithRelationInput = {
  TrainingData?: InputMaybe<TrainingDataOrderByWithRelationInput>;
  TrainingDataId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  relatedCompanies?: InputMaybe<CompanyOrderByWithRelationInput>;
  relatedCompaniesId?: InputMaybe<SortOrder>;
};

export enum RelatedCompanyInTrainingDataScalarFieldEnum {
  TrainingDataId = 'TrainingDataId',
  Id = 'id',
  RelatedCompaniesId = 'relatedCompaniesId'
}

export type RelatedCompanyInTrainingDataScalarWhereInput = {
  AND?: InputMaybe<Array<RelatedCompanyInTrainingDataScalarWhereInput>>;
  NOT?: InputMaybe<Array<RelatedCompanyInTrainingDataScalarWhereInput>>;
  OR?: InputMaybe<Array<RelatedCompanyInTrainingDataScalarWhereInput>>;
  TrainingDataId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  relatedCompaniesId?: InputMaybe<StringFilter>;
};

export type RelatedCompanyInTrainingDataScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<RelatedCompanyInTrainingDataScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<RelatedCompanyInTrainingDataScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<RelatedCompanyInTrainingDataScalarWhereWithAggregatesInput>>;
  TrainingDataId?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  relatedCompaniesId?: InputMaybe<StringWithAggregatesFilter>;
};

export type RelatedCompanyInTrainingDataUncheckedCreateInput = {
  TrainingDataId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  relatedCompaniesId: Scalars['String'];
};

export type RelatedCompanyInTrainingDataUncheckedCreateNestedManyWithoutRelatedCompaniesInput = {
  connect?: InputMaybe<Array<RelatedCompanyInTrainingDataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RelatedCompanyInTrainingDataCreateOrConnectWithoutRelatedCompaniesInput>>;
  create?: InputMaybe<Array<RelatedCompanyInTrainingDataCreateWithoutRelatedCompaniesInput>>;
  createMany?: InputMaybe<RelatedCompanyInTrainingDataCreateManyRelatedCompaniesInputEnvelope>;
};

export type RelatedCompanyInTrainingDataUncheckedCreateNestedManyWithoutTrainingDataInput = {
  connect?: InputMaybe<Array<RelatedCompanyInTrainingDataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RelatedCompanyInTrainingDataCreateOrConnectWithoutTrainingDataInput>>;
  create?: InputMaybe<Array<RelatedCompanyInTrainingDataCreateWithoutTrainingDataInput>>;
  createMany?: InputMaybe<RelatedCompanyInTrainingDataCreateManyTrainingDataInputEnvelope>;
};

export type RelatedCompanyInTrainingDataUncheckedCreateWithoutRelatedCompaniesInput = {
  TrainingDataId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
};

export type RelatedCompanyInTrainingDataUncheckedCreateWithoutTrainingDataInput = {
  id?: InputMaybe<Scalars['String']>;
  relatedCompaniesId: Scalars['String'];
};

export type RelatedCompanyInTrainingDataUncheckedUpdateInput = {
  TrainingDataId?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  relatedCompaniesId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type RelatedCompanyInTrainingDataUncheckedUpdateManyInput = {
  TrainingDataId?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  relatedCompaniesId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type RelatedCompanyInTrainingDataUncheckedUpdateManyWithoutRelatedCompaniesInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  relatedCompaniesId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type RelatedCompanyInTrainingDataUncheckedUpdateManyWithoutRelatedCompaniesNestedInput = {
  connect?: InputMaybe<Array<RelatedCompanyInTrainingDataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RelatedCompanyInTrainingDataCreateOrConnectWithoutRelatedCompaniesInput>>;
  create?: InputMaybe<Array<RelatedCompanyInTrainingDataCreateWithoutRelatedCompaniesInput>>;
  createMany?: InputMaybe<RelatedCompanyInTrainingDataCreateManyRelatedCompaniesInputEnvelope>;
  delete?: InputMaybe<Array<RelatedCompanyInTrainingDataWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RelatedCompanyInTrainingDataScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RelatedCompanyInTrainingDataWhereUniqueInput>>;
  set?: InputMaybe<Array<RelatedCompanyInTrainingDataWhereUniqueInput>>;
  update?: InputMaybe<Array<RelatedCompanyInTrainingDataUpdateWithWhereUniqueWithoutRelatedCompaniesInput>>;
  updateMany?: InputMaybe<Array<RelatedCompanyInTrainingDataUpdateManyWithWhereWithoutRelatedCompaniesInput>>;
  upsert?: InputMaybe<Array<RelatedCompanyInTrainingDataUpsertWithWhereUniqueWithoutRelatedCompaniesInput>>;
};

export type RelatedCompanyInTrainingDataUncheckedUpdateManyWithoutTrainedRelatedCompaniesInput = {
  TrainingDataId?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type RelatedCompanyInTrainingDataUncheckedUpdateManyWithoutTrainingDataNestedInput = {
  connect?: InputMaybe<Array<RelatedCompanyInTrainingDataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RelatedCompanyInTrainingDataCreateOrConnectWithoutTrainingDataInput>>;
  create?: InputMaybe<Array<RelatedCompanyInTrainingDataCreateWithoutTrainingDataInput>>;
  createMany?: InputMaybe<RelatedCompanyInTrainingDataCreateManyTrainingDataInputEnvelope>;
  delete?: InputMaybe<Array<RelatedCompanyInTrainingDataWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RelatedCompanyInTrainingDataScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RelatedCompanyInTrainingDataWhereUniqueInput>>;
  set?: InputMaybe<Array<RelatedCompanyInTrainingDataWhereUniqueInput>>;
  update?: InputMaybe<Array<RelatedCompanyInTrainingDataUpdateWithWhereUniqueWithoutTrainingDataInput>>;
  updateMany?: InputMaybe<Array<RelatedCompanyInTrainingDataUpdateManyWithWhereWithoutTrainingDataInput>>;
  upsert?: InputMaybe<Array<RelatedCompanyInTrainingDataUpsertWithWhereUniqueWithoutTrainingDataInput>>;
};

export type RelatedCompanyInTrainingDataUncheckedUpdateWithoutRelatedCompaniesInput = {
  TrainingDataId?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type RelatedCompanyInTrainingDataUncheckedUpdateWithoutTrainingDataInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  relatedCompaniesId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type RelatedCompanyInTrainingDataUpdateInput = {
  TrainingData?: InputMaybe<TrainingDataUpdateOneRequiredWithoutRelatedCompaniesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  relatedCompanies?: InputMaybe<CompanyUpdateOneRequiredWithoutTrainedRelatedCompaniesNestedInput>;
};

export type RelatedCompanyInTrainingDataUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type RelatedCompanyInTrainingDataUpdateManyWithWhereWithoutRelatedCompaniesInput = {
  data: RelatedCompanyInTrainingDataUncheckedUpdateManyWithoutTrainedRelatedCompaniesInput;
  where: RelatedCompanyInTrainingDataScalarWhereInput;
};

export type RelatedCompanyInTrainingDataUpdateManyWithWhereWithoutTrainingDataInput = {
  data: RelatedCompanyInTrainingDataUncheckedUpdateManyWithoutRelatedCompaniesInput;
  where: RelatedCompanyInTrainingDataScalarWhereInput;
};

export type RelatedCompanyInTrainingDataUpdateManyWithoutRelatedCompaniesNestedInput = {
  connect?: InputMaybe<Array<RelatedCompanyInTrainingDataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RelatedCompanyInTrainingDataCreateOrConnectWithoutRelatedCompaniesInput>>;
  create?: InputMaybe<Array<RelatedCompanyInTrainingDataCreateWithoutRelatedCompaniesInput>>;
  createMany?: InputMaybe<RelatedCompanyInTrainingDataCreateManyRelatedCompaniesInputEnvelope>;
  delete?: InputMaybe<Array<RelatedCompanyInTrainingDataWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RelatedCompanyInTrainingDataScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RelatedCompanyInTrainingDataWhereUniqueInput>>;
  set?: InputMaybe<Array<RelatedCompanyInTrainingDataWhereUniqueInput>>;
  update?: InputMaybe<Array<RelatedCompanyInTrainingDataUpdateWithWhereUniqueWithoutRelatedCompaniesInput>>;
  updateMany?: InputMaybe<Array<RelatedCompanyInTrainingDataUpdateManyWithWhereWithoutRelatedCompaniesInput>>;
  upsert?: InputMaybe<Array<RelatedCompanyInTrainingDataUpsertWithWhereUniqueWithoutRelatedCompaniesInput>>;
};

export type RelatedCompanyInTrainingDataUpdateManyWithoutTrainingDataNestedInput = {
  connect?: InputMaybe<Array<RelatedCompanyInTrainingDataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RelatedCompanyInTrainingDataCreateOrConnectWithoutTrainingDataInput>>;
  create?: InputMaybe<Array<RelatedCompanyInTrainingDataCreateWithoutTrainingDataInput>>;
  createMany?: InputMaybe<RelatedCompanyInTrainingDataCreateManyTrainingDataInputEnvelope>;
  delete?: InputMaybe<Array<RelatedCompanyInTrainingDataWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RelatedCompanyInTrainingDataScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RelatedCompanyInTrainingDataWhereUniqueInput>>;
  set?: InputMaybe<Array<RelatedCompanyInTrainingDataWhereUniqueInput>>;
  update?: InputMaybe<Array<RelatedCompanyInTrainingDataUpdateWithWhereUniqueWithoutTrainingDataInput>>;
  updateMany?: InputMaybe<Array<RelatedCompanyInTrainingDataUpdateManyWithWhereWithoutTrainingDataInput>>;
  upsert?: InputMaybe<Array<RelatedCompanyInTrainingDataUpsertWithWhereUniqueWithoutTrainingDataInput>>;
};

export type RelatedCompanyInTrainingDataUpdateWithWhereUniqueWithoutRelatedCompaniesInput = {
  data: RelatedCompanyInTrainingDataUncheckedUpdateWithoutRelatedCompaniesInput;
  where: RelatedCompanyInTrainingDataWhereUniqueInput;
};

export type RelatedCompanyInTrainingDataUpdateWithWhereUniqueWithoutTrainingDataInput = {
  data: RelatedCompanyInTrainingDataUncheckedUpdateWithoutTrainingDataInput;
  where: RelatedCompanyInTrainingDataWhereUniqueInput;
};

export type RelatedCompanyInTrainingDataUpdateWithoutRelatedCompaniesInput = {
  TrainingData?: InputMaybe<TrainingDataUpdateOneRequiredWithoutRelatedCompaniesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type RelatedCompanyInTrainingDataUpdateWithoutTrainingDataInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  relatedCompanies?: InputMaybe<CompanyUpdateOneRequiredWithoutTrainedRelatedCompaniesNestedInput>;
};

export type RelatedCompanyInTrainingDataUpsertWithWhereUniqueWithoutRelatedCompaniesInput = {
  create: RelatedCompanyInTrainingDataUncheckedCreateWithoutRelatedCompaniesInput;
  update: RelatedCompanyInTrainingDataUncheckedUpdateWithoutRelatedCompaniesInput;
  where: RelatedCompanyInTrainingDataWhereUniqueInput;
};

export type RelatedCompanyInTrainingDataUpsertWithWhereUniqueWithoutTrainingDataInput = {
  create: RelatedCompanyInTrainingDataUncheckedCreateWithoutTrainingDataInput;
  update: RelatedCompanyInTrainingDataUncheckedUpdateWithoutTrainingDataInput;
  where: RelatedCompanyInTrainingDataWhereUniqueInput;
};

export type RelatedCompanyInTrainingDataWhereInput = {
  AND?: InputMaybe<Array<RelatedCompanyInTrainingDataWhereInput>>;
  NOT?: InputMaybe<Array<RelatedCompanyInTrainingDataWhereInput>>;
  OR?: InputMaybe<Array<RelatedCompanyInTrainingDataWhereInput>>;
  TrainingData?: InputMaybe<TrainingDataWhereInput>;
  TrainingDataId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  relatedCompanies?: InputMaybe<CompanyWhereInput>;
  relatedCompaniesId?: InputMaybe<StringFilter>;
};

export type RelatedCompanyInTrainingDataWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type SimpleFineTuneRequest = {
  _count: SimpleFineTuneRequestCountOutputType;
  batchSize?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  learningRateMultiplier?: Maybe<Scalars['Float']>;
  model?: Maybe<Scalars['String']>;
  nEpochs?: Maybe<Scalars['Int']>;
  suffix?: Maybe<Scalars['String']>;
  trainingData: Array<SimpleTrainingData>;
  uploadedTrainingFileId?: Maybe<Scalars['String']>;
};


export type SimpleFineTuneRequestTrainingDataArgs = {
  cursor?: InputMaybe<SimpleTrainingDataWhereUniqueInput>;
  distinct?: InputMaybe<SimpleTrainingDataScalarFieldEnum>;
  orderBy?: InputMaybe<SimpleTrainingDataOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SimpleTrainingDataWhereInput>;
};

export type SimpleFineTuneRequestAvgAggregateOutputType = {
  batchSize?: Maybe<Scalars['Float']>;
  learningRateMultiplier?: Maybe<Scalars['Float']>;
  nEpochs?: Maybe<Scalars['Float']>;
};

export type SimpleFineTuneRequestAvgOrderByAggregateInput = {
  batchSize?: InputMaybe<SortOrder>;
  learningRateMultiplier?: InputMaybe<SortOrder>;
  nEpochs?: InputMaybe<SortOrder>;
};

export type SimpleFineTuneRequestCountAggregateOutputType = {
  _all: Scalars['Int'];
  batchSize: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  learningRateMultiplier: Scalars['Int'];
  model: Scalars['Int'];
  nEpochs: Scalars['Int'];
  suffix: Scalars['Int'];
  uploadedTrainingFileId: Scalars['Int'];
};

export type SimpleFineTuneRequestCountOrderByAggregateInput = {
  batchSize?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  learningRateMultiplier?: InputMaybe<SortOrder>;
  model?: InputMaybe<SortOrder>;
  nEpochs?: InputMaybe<SortOrder>;
  suffix?: InputMaybe<SortOrder>;
  uploadedTrainingFileId?: InputMaybe<SortOrder>;
};

export type SimpleFineTuneRequestCountOutputType = {
  trainingData: Scalars['Int'];
};

export type SimpleFineTuneRequestCreateInput = {
  batchSize?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  learningRateMultiplier?: InputMaybe<Scalars['Float']>;
  model?: InputMaybe<Scalars['String']>;
  nEpochs?: InputMaybe<Scalars['Int']>;
  suffix?: InputMaybe<Scalars['String']>;
  trainingData?: InputMaybe<SimpleTrainingDataCreateNestedManyWithoutFineTuneRequestInput>;
  uploadedTrainingFileId?: InputMaybe<Scalars['String']>;
};

export type SimpleFineTuneRequestCreateManyInput = {
  batchSize?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  learningRateMultiplier?: InputMaybe<Scalars['Float']>;
  model?: InputMaybe<Scalars['String']>;
  nEpochs?: InputMaybe<Scalars['Int']>;
  suffix?: InputMaybe<Scalars['String']>;
  uploadedTrainingFileId?: InputMaybe<Scalars['String']>;
};

export type SimpleFineTuneRequestCreateNestedOneWithoutTrainingDataInput = {
  connect?: InputMaybe<SimpleFineTuneRequestWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SimpleFineTuneRequestCreateOrConnectWithoutTrainingDataInput>;
  create?: InputMaybe<SimpleFineTuneRequestUncheckedCreateWithoutTrainingDataInput>;
};

export type SimpleFineTuneRequestCreateOrConnectWithoutTrainingDataInput = {
  create: SimpleFineTuneRequestUncheckedCreateWithoutTrainingDataInput;
  where: SimpleFineTuneRequestWhereUniqueInput;
};

export type SimpleFineTuneRequestCreateWithoutTrainingDataInput = {
  batchSize?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  learningRateMultiplier?: InputMaybe<Scalars['Float']>;
  model?: InputMaybe<Scalars['String']>;
  nEpochs?: InputMaybe<Scalars['Int']>;
  suffix?: InputMaybe<Scalars['String']>;
  uploadedTrainingFileId?: InputMaybe<Scalars['String']>;
};

export type SimpleFineTuneRequestMaxAggregateOutputType = {
  batchSize?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  learningRateMultiplier?: Maybe<Scalars['Float']>;
  model?: Maybe<Scalars['String']>;
  nEpochs?: Maybe<Scalars['Int']>;
  suffix?: Maybe<Scalars['String']>;
  uploadedTrainingFileId?: Maybe<Scalars['String']>;
};

export type SimpleFineTuneRequestMaxOrderByAggregateInput = {
  batchSize?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  learningRateMultiplier?: InputMaybe<SortOrder>;
  model?: InputMaybe<SortOrder>;
  nEpochs?: InputMaybe<SortOrder>;
  suffix?: InputMaybe<SortOrder>;
  uploadedTrainingFileId?: InputMaybe<SortOrder>;
};

export type SimpleFineTuneRequestMinAggregateOutputType = {
  batchSize?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  learningRateMultiplier?: Maybe<Scalars['Float']>;
  model?: Maybe<Scalars['String']>;
  nEpochs?: Maybe<Scalars['Int']>;
  suffix?: Maybe<Scalars['String']>;
  uploadedTrainingFileId?: Maybe<Scalars['String']>;
};

export type SimpleFineTuneRequestMinOrderByAggregateInput = {
  batchSize?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  learningRateMultiplier?: InputMaybe<SortOrder>;
  model?: InputMaybe<SortOrder>;
  nEpochs?: InputMaybe<SortOrder>;
  suffix?: InputMaybe<SortOrder>;
  uploadedTrainingFileId?: InputMaybe<SortOrder>;
};

export type SimpleFineTuneRequestOrderByWithAggregationInput = {
  _avg?: InputMaybe<SimpleFineTuneRequestAvgOrderByAggregateInput>;
  _count?: InputMaybe<SimpleFineTuneRequestCountOrderByAggregateInput>;
  _max?: InputMaybe<SimpleFineTuneRequestMaxOrderByAggregateInput>;
  _min?: InputMaybe<SimpleFineTuneRequestMinOrderByAggregateInput>;
  _sum?: InputMaybe<SimpleFineTuneRequestSumOrderByAggregateInput>;
  batchSize?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  learningRateMultiplier?: InputMaybe<SortOrder>;
  model?: InputMaybe<SortOrder>;
  nEpochs?: InputMaybe<SortOrder>;
  suffix?: InputMaybe<SortOrder>;
  uploadedTrainingFileId?: InputMaybe<SortOrder>;
};

export type SimpleFineTuneRequestOrderByWithRelationInput = {
  batchSize?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  learningRateMultiplier?: InputMaybe<SortOrder>;
  model?: InputMaybe<SortOrder>;
  nEpochs?: InputMaybe<SortOrder>;
  suffix?: InputMaybe<SortOrder>;
  trainingData?: InputMaybe<SimpleTrainingDataOrderByRelationAggregateInput>;
  uploadedTrainingFileId?: InputMaybe<SortOrder>;
};

export type SimpleFineTuneRequestRelationFilter = {
  is?: InputMaybe<SimpleFineTuneRequestWhereInput>;
  isNot?: InputMaybe<SimpleFineTuneRequestWhereInput>;
};

export enum SimpleFineTuneRequestScalarFieldEnum {
  BatchSize = 'batchSize',
  CreatedAt = 'createdAt',
  Id = 'id',
  LearningRateMultiplier = 'learningRateMultiplier',
  Model = 'model',
  NEpochs = 'nEpochs',
  Suffix = 'suffix',
  UploadedTrainingFileId = 'uploadedTrainingFileId'
}

export type SimpleFineTuneRequestScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<SimpleFineTuneRequestScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<SimpleFineTuneRequestScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<SimpleFineTuneRequestScalarWhereWithAggregatesInput>>;
  batchSize?: InputMaybe<IntNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  learningRateMultiplier?: InputMaybe<FloatNullableWithAggregatesFilter>;
  model?: InputMaybe<StringNullableWithAggregatesFilter>;
  nEpochs?: InputMaybe<IntNullableWithAggregatesFilter>;
  suffix?: InputMaybe<StringNullableWithAggregatesFilter>;
  uploadedTrainingFileId?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type SimpleFineTuneRequestSumAggregateOutputType = {
  batchSize?: Maybe<Scalars['Int']>;
  learningRateMultiplier?: Maybe<Scalars['Float']>;
  nEpochs?: Maybe<Scalars['Int']>;
};

export type SimpleFineTuneRequestSumOrderByAggregateInput = {
  batchSize?: InputMaybe<SortOrder>;
  learningRateMultiplier?: InputMaybe<SortOrder>;
  nEpochs?: InputMaybe<SortOrder>;
};

export type SimpleFineTuneRequestUncheckedCreateInput = {
  batchSize?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  learningRateMultiplier?: InputMaybe<Scalars['Float']>;
  model?: InputMaybe<Scalars['String']>;
  nEpochs?: InputMaybe<Scalars['Int']>;
  suffix?: InputMaybe<Scalars['String']>;
  trainingData?: InputMaybe<SimpleTrainingDataUncheckedCreateNestedManyWithoutFineTuneRequestInput>;
  uploadedTrainingFileId?: InputMaybe<Scalars['String']>;
};

export type SimpleFineTuneRequestUncheckedCreateWithoutTrainingDataInput = {
  batchSize?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  learningRateMultiplier?: InputMaybe<Scalars['Float']>;
  model?: InputMaybe<Scalars['String']>;
  nEpochs?: InputMaybe<Scalars['Int']>;
  suffix?: InputMaybe<Scalars['String']>;
  uploadedTrainingFileId?: InputMaybe<Scalars['String']>;
};

export type SimpleFineTuneRequestUncheckedUpdateInput = {
  batchSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  learningRateMultiplier?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  model?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  nEpochs?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  suffix?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  trainingData?: InputMaybe<SimpleTrainingDataUncheckedUpdateManyWithoutFineTuneRequestNestedInput>;
  uploadedTrainingFileId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type SimpleFineTuneRequestUncheckedUpdateManyInput = {
  batchSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  learningRateMultiplier?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  model?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  nEpochs?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  suffix?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uploadedTrainingFileId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type SimpleFineTuneRequestUncheckedUpdateWithoutTrainingDataInput = {
  batchSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  learningRateMultiplier?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  model?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  nEpochs?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  suffix?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uploadedTrainingFileId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type SimpleFineTuneRequestUpdateInput = {
  batchSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  learningRateMultiplier?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  model?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  nEpochs?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  suffix?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  trainingData?: InputMaybe<SimpleTrainingDataUpdateManyWithoutFineTuneRequestNestedInput>;
  uploadedTrainingFileId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type SimpleFineTuneRequestUpdateManyMutationInput = {
  batchSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  learningRateMultiplier?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  model?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  nEpochs?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  suffix?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uploadedTrainingFileId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type SimpleFineTuneRequestUpdateOneWithoutTrainingDataNestedInput = {
  connect?: InputMaybe<SimpleFineTuneRequestWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SimpleFineTuneRequestCreateOrConnectWithoutTrainingDataInput>;
  create?: InputMaybe<SimpleFineTuneRequestUncheckedCreateWithoutTrainingDataInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<SimpleFineTuneRequestUncheckedUpdateWithoutTrainingDataInput>;
  upsert?: InputMaybe<SimpleFineTuneRequestUpsertWithoutTrainingDataInput>;
};

export type SimpleFineTuneRequestUpdateWithoutTrainingDataInput = {
  batchSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  learningRateMultiplier?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  model?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  nEpochs?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  suffix?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uploadedTrainingFileId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type SimpleFineTuneRequestUpsertWithoutTrainingDataInput = {
  create: SimpleFineTuneRequestUncheckedCreateWithoutTrainingDataInput;
  update: SimpleFineTuneRequestUncheckedUpdateWithoutTrainingDataInput;
};

export type SimpleFineTuneRequestWhereInput = {
  AND?: InputMaybe<Array<SimpleFineTuneRequestWhereInput>>;
  NOT?: InputMaybe<Array<SimpleFineTuneRequestWhereInput>>;
  OR?: InputMaybe<Array<SimpleFineTuneRequestWhereInput>>;
  batchSize?: InputMaybe<IntNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  learningRateMultiplier?: InputMaybe<FloatNullableFilter>;
  model?: InputMaybe<StringNullableFilter>;
  nEpochs?: InputMaybe<IntNullableFilter>;
  suffix?: InputMaybe<StringNullableFilter>;
  trainingData?: InputMaybe<SimpleTrainingDataListRelationFilter>;
  uploadedTrainingFileId?: InputMaybe<StringNullableFilter>;
};

export type SimpleFineTuneRequestWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type SimpleTrainingData = {
  company: Scalars['String'];
  companyDescription?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  fineTuneRequest?: Maybe<SimpleFineTuneRequest>;
  fineTuneRequestId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  jobCode?: Maybe<Scalars['String']>;
  relatedCompanies: Array<Scalars['String']>;
  relatedCompaniesStockCode: Array<Scalars['String']>;
};

export type SimpleTrainingDataCountAggregateOutputType = {
  _all: Scalars['Int'];
  company: Scalars['Int'];
  companyDescription: Scalars['Int'];
  createdAt: Scalars['Int'];
  createdBy: Scalars['Int'];
  fineTuneRequestId: Scalars['Int'];
  id: Scalars['Int'];
  jobCode: Scalars['Int'];
  relatedCompanies: Scalars['Int'];
  relatedCompaniesStockCode: Scalars['Int'];
};

export type SimpleTrainingDataCountOrderByAggregateInput = {
  company?: InputMaybe<SortOrder>;
  companyDescription?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  fineTuneRequestId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  jobCode?: InputMaybe<SortOrder>;
  relatedCompanies?: InputMaybe<SortOrder>;
  relatedCompaniesStockCode?: InputMaybe<SortOrder>;
};

export type SimpleTrainingDataCreateInput = {
  company: Scalars['String'];
  companyDescription?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<Scalars['String']>;
  fineTuneRequest?: InputMaybe<SimpleFineTuneRequestCreateNestedOneWithoutTrainingDataInput>;
  id?: InputMaybe<Scalars['String']>;
  jobCode?: InputMaybe<Scalars['String']>;
  relatedCompanies?: InputMaybe<Array<Scalars['String']>>;
  relatedCompaniesStockCode?: InputMaybe<Array<Scalars['String']>>;
};

export type SimpleTrainingDataCreateManyFineTuneRequestInput = {
  company: Scalars['String'];
  companyDescription?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  jobCode?: InputMaybe<Scalars['String']>;
  relatedCompanies?: InputMaybe<Array<Scalars['String']>>;
  relatedCompaniesStockCode?: InputMaybe<Array<Scalars['String']>>;
};

export type SimpleTrainingDataCreateManyFineTuneRequestInputEnvelope = {
  data: Array<SimpleTrainingDataCreateManyFineTuneRequestInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type SimpleTrainingDataCreateManyInput = {
  company: Scalars['String'];
  companyDescription?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<Scalars['String']>;
  fineTuneRequestId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  jobCode?: InputMaybe<Scalars['String']>;
  relatedCompanies?: InputMaybe<Array<Scalars['String']>>;
  relatedCompaniesStockCode?: InputMaybe<Array<Scalars['String']>>;
};

export type SimpleTrainingDataCreateNestedManyWithoutFineTuneRequestInput = {
  connect?: InputMaybe<Array<SimpleTrainingDataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SimpleTrainingDataCreateOrConnectWithoutFineTuneRequestInput>>;
  create?: InputMaybe<Array<SimpleTrainingDataCreateWithoutFineTuneRequestInput>>;
  createMany?: InputMaybe<SimpleTrainingDataCreateManyFineTuneRequestInputEnvelope>;
};

export type SimpleTrainingDataCreateOrConnectWithoutFineTuneRequestInput = {
  create: SimpleTrainingDataUncheckedCreateWithoutFineTuneRequestInput;
  where: SimpleTrainingDataWhereUniqueInput;
};

export type SimpleTrainingDataCreateWithoutFineTuneRequestInput = {
  company: Scalars['String'];
  companyDescription?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  jobCode?: InputMaybe<Scalars['String']>;
  relatedCompanies?: InputMaybe<Array<Scalars['String']>>;
  relatedCompaniesStockCode?: InputMaybe<Array<Scalars['String']>>;
};

export type SimpleTrainingDataCreaterelatedCompaniesInput = {
  set: Array<Scalars['String']>;
};

export type SimpleTrainingDataCreaterelatedCompaniesStockCodeInput = {
  set: Array<Scalars['String']>;
};

export type SimpleTrainingDataListRelationFilter = {
  every?: InputMaybe<SimpleTrainingDataWhereInput>;
  none?: InputMaybe<SimpleTrainingDataWhereInput>;
  some?: InputMaybe<SimpleTrainingDataWhereInput>;
};

export type SimpleTrainingDataMaxAggregateOutputType = {
  company?: Maybe<Scalars['String']>;
  companyDescription?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  fineTuneRequestId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  jobCode?: Maybe<Scalars['String']>;
};

export type SimpleTrainingDataMaxOrderByAggregateInput = {
  company?: InputMaybe<SortOrder>;
  companyDescription?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  fineTuneRequestId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  jobCode?: InputMaybe<SortOrder>;
};

export type SimpleTrainingDataMinAggregateOutputType = {
  company?: Maybe<Scalars['String']>;
  companyDescription?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  fineTuneRequestId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  jobCode?: Maybe<Scalars['String']>;
};

export type SimpleTrainingDataMinOrderByAggregateInput = {
  company?: InputMaybe<SortOrder>;
  companyDescription?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  fineTuneRequestId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  jobCode?: InputMaybe<SortOrder>;
};

export type SimpleTrainingDataOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type SimpleTrainingDataOrderByWithAggregationInput = {
  _count?: InputMaybe<SimpleTrainingDataCountOrderByAggregateInput>;
  _max?: InputMaybe<SimpleTrainingDataMaxOrderByAggregateInput>;
  _min?: InputMaybe<SimpleTrainingDataMinOrderByAggregateInput>;
  company?: InputMaybe<SortOrder>;
  companyDescription?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  fineTuneRequestId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  jobCode?: InputMaybe<SortOrder>;
  relatedCompanies?: InputMaybe<SortOrder>;
  relatedCompaniesStockCode?: InputMaybe<SortOrder>;
};

export type SimpleTrainingDataOrderByWithRelationInput = {
  company?: InputMaybe<SortOrder>;
  companyDescription?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  fineTuneRequest?: InputMaybe<SimpleFineTuneRequestOrderByWithRelationInput>;
  fineTuneRequestId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  jobCode?: InputMaybe<SortOrder>;
  relatedCompanies?: InputMaybe<SortOrder>;
  relatedCompaniesStockCode?: InputMaybe<SortOrder>;
};

export enum SimpleTrainingDataScalarFieldEnum {
  Company = 'company',
  CompanyDescription = 'companyDescription',
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  FineTuneRequestId = 'fineTuneRequestId',
  Id = 'id',
  JobCode = 'jobCode',
  RelatedCompanies = 'relatedCompanies',
  RelatedCompaniesStockCode = 'relatedCompaniesStockCode'
}

export type SimpleTrainingDataScalarWhereInput = {
  AND?: InputMaybe<Array<SimpleTrainingDataScalarWhereInput>>;
  NOT?: InputMaybe<Array<SimpleTrainingDataScalarWhereInput>>;
  OR?: InputMaybe<Array<SimpleTrainingDataScalarWhereInput>>;
  company?: InputMaybe<StringFilter>;
  companyDescription?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<StringNullableFilter>;
  fineTuneRequestId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  jobCode?: InputMaybe<StringNullableFilter>;
  relatedCompanies?: InputMaybe<StringNullableListFilter>;
  relatedCompaniesStockCode?: InputMaybe<StringNullableListFilter>;
};

export type SimpleTrainingDataScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<SimpleTrainingDataScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<SimpleTrainingDataScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<SimpleTrainingDataScalarWhereWithAggregatesInput>>;
  company?: InputMaybe<StringWithAggregatesFilter>;
  companyDescription?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  createdBy?: InputMaybe<StringNullableWithAggregatesFilter>;
  fineTuneRequestId?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  jobCode?: InputMaybe<StringNullableWithAggregatesFilter>;
  relatedCompanies?: InputMaybe<StringNullableListFilter>;
  relatedCompaniesStockCode?: InputMaybe<StringNullableListFilter>;
};

export type SimpleTrainingDataUncheckedCreateInput = {
  company: Scalars['String'];
  companyDescription?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<Scalars['String']>;
  fineTuneRequestId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  jobCode?: InputMaybe<Scalars['String']>;
  relatedCompanies?: InputMaybe<Array<Scalars['String']>>;
  relatedCompaniesStockCode?: InputMaybe<Array<Scalars['String']>>;
};

export type SimpleTrainingDataUncheckedCreateNestedManyWithoutFineTuneRequestInput = {
  connect?: InputMaybe<Array<SimpleTrainingDataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SimpleTrainingDataCreateOrConnectWithoutFineTuneRequestInput>>;
  create?: InputMaybe<Array<SimpleTrainingDataCreateWithoutFineTuneRequestInput>>;
  createMany?: InputMaybe<SimpleTrainingDataCreateManyFineTuneRequestInputEnvelope>;
};

export type SimpleTrainingDataUncheckedCreateWithoutFineTuneRequestInput = {
  company: Scalars['String'];
  companyDescription?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  jobCode?: InputMaybe<Scalars['String']>;
  relatedCompanies?: InputMaybe<Array<Scalars['String']>>;
  relatedCompaniesStockCode?: InputMaybe<Array<Scalars['String']>>;
};

export type SimpleTrainingDataUncheckedUpdateInput = {
  company?: InputMaybe<StringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fineTuneRequestId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  jobCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  relatedCompanies?: InputMaybe<Array<Scalars['String']>>;
  relatedCompaniesStockCode?: InputMaybe<Array<Scalars['String']>>;
};

export type SimpleTrainingDataUncheckedUpdateManyInput = {
  company?: InputMaybe<StringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fineTuneRequestId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  jobCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  relatedCompanies?: InputMaybe<Array<Scalars['String']>>;
  relatedCompaniesStockCode?: InputMaybe<Array<Scalars['String']>>;
};

export type SimpleTrainingDataUncheckedUpdateManyWithoutFineTuneRequestNestedInput = {
  connect?: InputMaybe<Array<SimpleTrainingDataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SimpleTrainingDataCreateOrConnectWithoutFineTuneRequestInput>>;
  create?: InputMaybe<Array<SimpleTrainingDataCreateWithoutFineTuneRequestInput>>;
  createMany?: InputMaybe<SimpleTrainingDataCreateManyFineTuneRequestInputEnvelope>;
  delete?: InputMaybe<Array<SimpleTrainingDataWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SimpleTrainingDataScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SimpleTrainingDataWhereUniqueInput>>;
  set?: InputMaybe<Array<SimpleTrainingDataWhereUniqueInput>>;
  update?: InputMaybe<Array<SimpleTrainingDataUpdateWithWhereUniqueWithoutFineTuneRequestInput>>;
  updateMany?: InputMaybe<Array<SimpleTrainingDataUpdateManyWithWhereWithoutFineTuneRequestInput>>;
  upsert?: InputMaybe<Array<SimpleTrainingDataUpsertWithWhereUniqueWithoutFineTuneRequestInput>>;
};

export type SimpleTrainingDataUncheckedUpdateManyWithoutTrainingDataInput = {
  company?: InputMaybe<StringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  jobCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  relatedCompanies?: InputMaybe<Array<Scalars['String']>>;
  relatedCompaniesStockCode?: InputMaybe<Array<Scalars['String']>>;
};

export type SimpleTrainingDataUncheckedUpdateWithoutFineTuneRequestInput = {
  company?: InputMaybe<StringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  jobCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  relatedCompanies?: InputMaybe<Array<Scalars['String']>>;
  relatedCompaniesStockCode?: InputMaybe<Array<Scalars['String']>>;
};

export type SimpleTrainingDataUpdateInput = {
  company?: InputMaybe<StringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fineTuneRequest?: InputMaybe<SimpleFineTuneRequestUpdateOneWithoutTrainingDataNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  jobCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  relatedCompanies?: InputMaybe<Array<Scalars['String']>>;
  relatedCompaniesStockCode?: InputMaybe<Array<Scalars['String']>>;
};

export type SimpleTrainingDataUpdateManyMutationInput = {
  company?: InputMaybe<StringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  jobCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  relatedCompanies?: InputMaybe<Array<Scalars['String']>>;
  relatedCompaniesStockCode?: InputMaybe<Array<Scalars['String']>>;
};

export type SimpleTrainingDataUpdateManyWithWhereWithoutFineTuneRequestInput = {
  data: SimpleTrainingDataUncheckedUpdateManyWithoutTrainingDataInput;
  where: SimpleTrainingDataScalarWhereInput;
};

export type SimpleTrainingDataUpdateManyWithoutFineTuneRequestNestedInput = {
  connect?: InputMaybe<Array<SimpleTrainingDataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SimpleTrainingDataCreateOrConnectWithoutFineTuneRequestInput>>;
  create?: InputMaybe<Array<SimpleTrainingDataCreateWithoutFineTuneRequestInput>>;
  createMany?: InputMaybe<SimpleTrainingDataCreateManyFineTuneRequestInputEnvelope>;
  delete?: InputMaybe<Array<SimpleTrainingDataWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SimpleTrainingDataScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SimpleTrainingDataWhereUniqueInput>>;
  set?: InputMaybe<Array<SimpleTrainingDataWhereUniqueInput>>;
  update?: InputMaybe<Array<SimpleTrainingDataUpdateWithWhereUniqueWithoutFineTuneRequestInput>>;
  updateMany?: InputMaybe<Array<SimpleTrainingDataUpdateManyWithWhereWithoutFineTuneRequestInput>>;
  upsert?: InputMaybe<Array<SimpleTrainingDataUpsertWithWhereUniqueWithoutFineTuneRequestInput>>;
};

export type SimpleTrainingDataUpdateWithWhereUniqueWithoutFineTuneRequestInput = {
  data: SimpleTrainingDataUncheckedUpdateWithoutFineTuneRequestInput;
  where: SimpleTrainingDataWhereUniqueInput;
};

export type SimpleTrainingDataUpdateWithoutFineTuneRequestInput = {
  company?: InputMaybe<StringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  jobCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  relatedCompanies?: InputMaybe<Array<Scalars['String']>>;
  relatedCompaniesStockCode?: InputMaybe<Array<Scalars['String']>>;
};

export type SimpleTrainingDataUpdaterelatedCompaniesInput = {
  push?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type SimpleTrainingDataUpdaterelatedCompaniesStockCodeInput = {
  push?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type SimpleTrainingDataUpsertWithWhereUniqueWithoutFineTuneRequestInput = {
  create: SimpleTrainingDataUncheckedCreateWithoutFineTuneRequestInput;
  update: SimpleTrainingDataUncheckedUpdateWithoutFineTuneRequestInput;
  where: SimpleTrainingDataWhereUniqueInput;
};

export type SimpleTrainingDataWhereInput = {
  AND?: InputMaybe<Array<SimpleTrainingDataWhereInput>>;
  NOT?: InputMaybe<Array<SimpleTrainingDataWhereInput>>;
  OR?: InputMaybe<Array<SimpleTrainingDataWhereInput>>;
  company?: InputMaybe<StringFilter>;
  companyDescription?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<StringNullableFilter>;
  fineTuneRequest?: InputMaybe<SimpleFineTuneRequestWhereInput>;
  fineTuneRequestId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  jobCode?: InputMaybe<StringNullableFilter>;
  relatedCompanies?: InputMaybe<StringNullableListFilter>;
  relatedCompaniesStockCode?: InputMaybe<StringNullableListFilter>;
};

export type SimpleTrainingDataWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableListFilter = {
  equals?: InputMaybe<Array<Scalars['String']>>;
  has?: InputMaybe<Scalars['String']>;
  hasEvery?: InputMaybe<Array<Scalars['String']>>;
  hasSome?: InputMaybe<Array<Scalars['String']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export type StringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type TrainingData = {
  _count: TrainingDataCountOutputType;
  company: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  fineTuneRequest?: Maybe<FineTuneRequest>;
  fineTuneRequestId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  relatedCompanies: Array<RelatedCompanyInTrainingData>;
};


export type TrainingDataRelatedCompaniesArgs = {
  cursor?: InputMaybe<RelatedCompanyInTrainingDataWhereUniqueInput>;
  distinct?: InputMaybe<RelatedCompanyInTrainingDataScalarFieldEnum>;
  orderBy?: InputMaybe<RelatedCompanyInTrainingDataOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RelatedCompanyInTrainingDataWhereInput>;
};

export type TrainingDataCountAggregateOutputType = {
  _all: Scalars['Int'];
  company: Scalars['Int'];
  createdAt: Scalars['Int'];
  createdBy: Scalars['Int'];
  fineTuneRequestId: Scalars['Int'];
  id: Scalars['Int'];
};

export type TrainingDataCountOrderByAggregateInput = {
  company?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  fineTuneRequestId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type TrainingDataCountOutputType = {
  relatedCompanies: Scalars['Int'];
};

export type TrainingDataCreateInput = {
  company: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<Scalars['String']>;
  fineTuneRequest?: InputMaybe<FineTuneRequestCreateNestedOneWithoutTrainingDataInput>;
  id?: InputMaybe<Scalars['String']>;
  relatedCompanies?: InputMaybe<RelatedCompanyInTrainingDataCreateNestedManyWithoutTrainingDataInput>;
};

export type TrainingDataCreateManyFineTuneRequestInput = {
  company: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type TrainingDataCreateManyFineTuneRequestInputEnvelope = {
  data: Array<TrainingDataCreateManyFineTuneRequestInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TrainingDataCreateManyInput = {
  company: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<Scalars['String']>;
  fineTuneRequestId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type TrainingDataCreateNestedManyWithoutFineTuneRequestInput = {
  connect?: InputMaybe<Array<TrainingDataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TrainingDataCreateOrConnectWithoutFineTuneRequestInput>>;
  create?: InputMaybe<Array<TrainingDataCreateWithoutFineTuneRequestInput>>;
  createMany?: InputMaybe<TrainingDataCreateManyFineTuneRequestInputEnvelope>;
};

export type TrainingDataCreateNestedOneWithoutRelatedCompaniesInput = {
  connect?: InputMaybe<TrainingDataWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TrainingDataCreateOrConnectWithoutRelatedCompaniesInput>;
  create?: InputMaybe<TrainingDataUncheckedCreateWithoutRelatedCompaniesInput>;
};

export type TrainingDataCreateOrConnectWithoutFineTuneRequestInput = {
  create: TrainingDataUncheckedCreateWithoutFineTuneRequestInput;
  where: TrainingDataWhereUniqueInput;
};

export type TrainingDataCreateOrConnectWithoutRelatedCompaniesInput = {
  create: TrainingDataUncheckedCreateWithoutRelatedCompaniesInput;
  where: TrainingDataWhereUniqueInput;
};

export type TrainingDataCreateWithoutFineTuneRequestInput = {
  company: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  relatedCompanies?: InputMaybe<RelatedCompanyInTrainingDataCreateNestedManyWithoutTrainingDataInput>;
};

export type TrainingDataCreateWithoutRelatedCompaniesInput = {
  company: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<Scalars['String']>;
  fineTuneRequest?: InputMaybe<FineTuneRequestCreateNestedOneWithoutTrainingDataInput>;
  id?: InputMaybe<Scalars['String']>;
};

export type TrainingDataListRelationFilter = {
  every?: InputMaybe<TrainingDataWhereInput>;
  none?: InputMaybe<TrainingDataWhereInput>;
  some?: InputMaybe<TrainingDataWhereInput>;
};

export type TrainingDataMaxAggregateOutputType = {
  company?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  fineTuneRequestId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type TrainingDataMaxOrderByAggregateInput = {
  company?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  fineTuneRequestId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type TrainingDataMinAggregateOutputType = {
  company?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  fineTuneRequestId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type TrainingDataMinOrderByAggregateInput = {
  company?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  fineTuneRequestId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type TrainingDataOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TrainingDataOrderByWithAggregationInput = {
  _count?: InputMaybe<TrainingDataCountOrderByAggregateInput>;
  _max?: InputMaybe<TrainingDataMaxOrderByAggregateInput>;
  _min?: InputMaybe<TrainingDataMinOrderByAggregateInput>;
  company?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  fineTuneRequestId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type TrainingDataOrderByWithRelationInput = {
  company?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  fineTuneRequest?: InputMaybe<FineTuneRequestOrderByWithRelationInput>;
  fineTuneRequestId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  relatedCompanies?: InputMaybe<RelatedCompanyInTrainingDataOrderByRelationAggregateInput>;
};

export type TrainingDataRelationFilter = {
  is?: InputMaybe<TrainingDataWhereInput>;
  isNot?: InputMaybe<TrainingDataWhereInput>;
};

export enum TrainingDataScalarFieldEnum {
  Company = 'company',
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  FineTuneRequestId = 'fineTuneRequestId',
  Id = 'id'
}

export type TrainingDataScalarWhereInput = {
  AND?: InputMaybe<Array<TrainingDataScalarWhereInput>>;
  NOT?: InputMaybe<Array<TrainingDataScalarWhereInput>>;
  OR?: InputMaybe<Array<TrainingDataScalarWhereInput>>;
  company?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<StringNullableFilter>;
  fineTuneRequestId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
};

export type TrainingDataScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<TrainingDataScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<TrainingDataScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<TrainingDataScalarWhereWithAggregatesInput>>;
  company?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  createdBy?: InputMaybe<StringNullableWithAggregatesFilter>;
  fineTuneRequestId?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
};

export type TrainingDataUncheckedCreateInput = {
  company: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<Scalars['String']>;
  fineTuneRequestId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  relatedCompanies?: InputMaybe<RelatedCompanyInTrainingDataUncheckedCreateNestedManyWithoutTrainingDataInput>;
};

export type TrainingDataUncheckedCreateNestedManyWithoutFineTuneRequestInput = {
  connect?: InputMaybe<Array<TrainingDataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TrainingDataCreateOrConnectWithoutFineTuneRequestInput>>;
  create?: InputMaybe<Array<TrainingDataCreateWithoutFineTuneRequestInput>>;
  createMany?: InputMaybe<TrainingDataCreateManyFineTuneRequestInputEnvelope>;
};

export type TrainingDataUncheckedCreateWithoutFineTuneRequestInput = {
  company: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  relatedCompanies?: InputMaybe<RelatedCompanyInTrainingDataUncheckedCreateNestedManyWithoutTrainingDataInput>;
};

export type TrainingDataUncheckedCreateWithoutRelatedCompaniesInput = {
  company: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<Scalars['String']>;
  fineTuneRequestId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type TrainingDataUncheckedUpdateInput = {
  company?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fineTuneRequestId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  relatedCompanies?: InputMaybe<RelatedCompanyInTrainingDataUncheckedUpdateManyWithoutTrainingDataNestedInput>;
};

export type TrainingDataUncheckedUpdateManyInput = {
  company?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fineTuneRequestId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type TrainingDataUncheckedUpdateManyWithoutFineTuneRequestNestedInput = {
  connect?: InputMaybe<Array<TrainingDataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TrainingDataCreateOrConnectWithoutFineTuneRequestInput>>;
  create?: InputMaybe<Array<TrainingDataCreateWithoutFineTuneRequestInput>>;
  createMany?: InputMaybe<TrainingDataCreateManyFineTuneRequestInputEnvelope>;
  delete?: InputMaybe<Array<TrainingDataWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TrainingDataScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TrainingDataWhereUniqueInput>>;
  set?: InputMaybe<Array<TrainingDataWhereUniqueInput>>;
  update?: InputMaybe<Array<TrainingDataUpdateWithWhereUniqueWithoutFineTuneRequestInput>>;
  updateMany?: InputMaybe<Array<TrainingDataUpdateManyWithWhereWithoutFineTuneRequestInput>>;
  upsert?: InputMaybe<Array<TrainingDataUpsertWithWhereUniqueWithoutFineTuneRequestInput>>;
};

export type TrainingDataUncheckedUpdateManyWithoutTrainingDataInput = {
  company?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type TrainingDataUncheckedUpdateWithoutFineTuneRequestInput = {
  company?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  relatedCompanies?: InputMaybe<RelatedCompanyInTrainingDataUncheckedUpdateManyWithoutTrainingDataNestedInput>;
};

export type TrainingDataUncheckedUpdateWithoutRelatedCompaniesInput = {
  company?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fineTuneRequestId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type TrainingDataUpdateInput = {
  company?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fineTuneRequest?: InputMaybe<FineTuneRequestUpdateOneWithoutTrainingDataNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  relatedCompanies?: InputMaybe<RelatedCompanyInTrainingDataUpdateManyWithoutTrainingDataNestedInput>;
};

export type TrainingDataUpdateManyMutationInput = {
  company?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type TrainingDataUpdateManyWithWhereWithoutFineTuneRequestInput = {
  data: TrainingDataUncheckedUpdateManyWithoutTrainingDataInput;
  where: TrainingDataScalarWhereInput;
};

export type TrainingDataUpdateManyWithoutFineTuneRequestNestedInput = {
  connect?: InputMaybe<Array<TrainingDataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TrainingDataCreateOrConnectWithoutFineTuneRequestInput>>;
  create?: InputMaybe<Array<TrainingDataCreateWithoutFineTuneRequestInput>>;
  createMany?: InputMaybe<TrainingDataCreateManyFineTuneRequestInputEnvelope>;
  delete?: InputMaybe<Array<TrainingDataWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TrainingDataScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TrainingDataWhereUniqueInput>>;
  set?: InputMaybe<Array<TrainingDataWhereUniqueInput>>;
  update?: InputMaybe<Array<TrainingDataUpdateWithWhereUniqueWithoutFineTuneRequestInput>>;
  updateMany?: InputMaybe<Array<TrainingDataUpdateManyWithWhereWithoutFineTuneRequestInput>>;
  upsert?: InputMaybe<Array<TrainingDataUpsertWithWhereUniqueWithoutFineTuneRequestInput>>;
};

export type TrainingDataUpdateOneRequiredWithoutRelatedCompaniesNestedInput = {
  connect?: InputMaybe<TrainingDataWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TrainingDataCreateOrConnectWithoutRelatedCompaniesInput>;
  create?: InputMaybe<TrainingDataUncheckedCreateWithoutRelatedCompaniesInput>;
  update?: InputMaybe<TrainingDataUncheckedUpdateWithoutRelatedCompaniesInput>;
  upsert?: InputMaybe<TrainingDataUpsertWithoutRelatedCompaniesInput>;
};

export type TrainingDataUpdateWithWhereUniqueWithoutFineTuneRequestInput = {
  data: TrainingDataUncheckedUpdateWithoutFineTuneRequestInput;
  where: TrainingDataWhereUniqueInput;
};

export type TrainingDataUpdateWithoutFineTuneRequestInput = {
  company?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  relatedCompanies?: InputMaybe<RelatedCompanyInTrainingDataUpdateManyWithoutTrainingDataNestedInput>;
};

export type TrainingDataUpdateWithoutRelatedCompaniesInput = {
  company?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fineTuneRequest?: InputMaybe<FineTuneRequestUpdateOneWithoutTrainingDataNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type TrainingDataUpsertWithWhereUniqueWithoutFineTuneRequestInput = {
  create: TrainingDataUncheckedCreateWithoutFineTuneRequestInput;
  update: TrainingDataUncheckedUpdateWithoutFineTuneRequestInput;
  where: TrainingDataWhereUniqueInput;
};

export type TrainingDataUpsertWithoutRelatedCompaniesInput = {
  create: TrainingDataUncheckedCreateWithoutRelatedCompaniesInput;
  update: TrainingDataUncheckedUpdateWithoutRelatedCompaniesInput;
};

export type TrainingDataWhereInput = {
  AND?: InputMaybe<Array<TrainingDataWhereInput>>;
  NOT?: InputMaybe<Array<TrainingDataWhereInput>>;
  OR?: InputMaybe<Array<TrainingDataWhereInput>>;
  company?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<StringNullableFilter>;
  fineTuneRequest?: InputMaybe<FineTuneRequestWhereInput>;
  fineTuneRequestId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  relatedCompanies?: InputMaybe<RelatedCompanyInTrainingDataListRelationFilter>;
};

export type TrainingDataWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export enum TransactionIsolationLevel {
  ReadCommitted = 'ReadCommitted',
  ReadUncommitted = 'ReadUncommitted',
  RepeatableRead = 'RepeatableRead',
  Serializable = 'Serializable'
}

export type CompanyFieldsFragment = { id: string, name: string, description?: string | null, nativeName?: string | null, stockExchange: string, ticker: string };

export type FeedbackDataFieldFragment = { id: string, createdAt: any, createdBy?: string | null };

export type GetOpenAiModelsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOpenAiModelsQuery = { getOpenAiModels?: Array<string | null> | null };

export type FindManyCompanyQueryVariables = Exact<{
  where?: InputMaybe<CompanyWhereInput>;
  orderBy?: InputMaybe<Array<InputMaybe<CompanyOrderByWithRelationInput>> | InputMaybe<CompanyOrderByWithRelationInput>>;
}>;


export type FindManyCompanyQuery = { findManyCompany?: Array<{ id: string, name: string, description?: string | null, nativeName?: string | null, stockExchange: string, ticker: string }> | null };

export type GetCompaniesDropdownOptionsQueryVariables = Exact<{
  keyword?: InputMaybe<Scalars['String']>;
}>;


export type GetCompaniesDropdownOptionsQuery = { getCompaniesDropdownOptions?: Array<{ id: string, name: string, description?: string | null, nativeName?: string | null, stockExchange: string, ticker: string }> | null };

export type SearchRelatedCompaniesQueryVariables = Exact<{
  searchString?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
}>;


export type SearchRelatedCompaniesQuery = { searchRelatedCompanies?: Array<{ id: string, name: string, description?: string | null, nativeName?: string | null, stockExchange: string, ticker: string }> | null };

export type CreateFeedbackDataMutationVariables = Exact<{
  company: Scalars['String'];
  companyDescription: Scalars['String'];
  companyWebsite: Scalars['String'];
  aiRelatedCompanies: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
  aiRelatedCompaniesStockCode: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
  relatedCompanies: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
  relatedCompaniesStockCode: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
}>;


export type CreateFeedbackDataMutation = { createFeedbackData?: { id: string, createdAt: any, createdBy?: string | null } | null };

export const CompanyFieldsFragmentDoc = gql`
    fragment companyFields on Company {
  id
  name
  description
  nativeName
  stockExchange
  ticker
}
    `;
export const FeedbackDataFieldFragmentDoc = gql`
    fragment FeedbackDataField on FeedbackData {
  id
  createdAt
  createdBy
}
    `;
export const GetOpenAiModelsDocument = gql`
    query getOpenAiModels {
  getOpenAiModels
}
    `;

/**
 * __useGetOpenAiModelsQuery__
 *
 * To run a query within a React component, call `useGetOpenAiModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOpenAiModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOpenAiModelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOpenAiModelsQuery(baseOptions?: Apollo.QueryHookOptions<GetOpenAiModelsQuery, GetOpenAiModelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOpenAiModelsQuery, GetOpenAiModelsQueryVariables>(GetOpenAiModelsDocument, options);
      }
export function useGetOpenAiModelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOpenAiModelsQuery, GetOpenAiModelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOpenAiModelsQuery, GetOpenAiModelsQueryVariables>(GetOpenAiModelsDocument, options);
        }
export type GetOpenAiModelsQueryHookResult = ReturnType<typeof useGetOpenAiModelsQuery>;
export type GetOpenAiModelsLazyQueryHookResult = ReturnType<typeof useGetOpenAiModelsLazyQuery>;
export type GetOpenAiModelsQueryResult = Apollo.QueryResult<GetOpenAiModelsQuery, GetOpenAiModelsQueryVariables>;
export const FindManyCompanyDocument = gql`
    query findManyCompany($where: CompanyWhereInput, $orderBy: [CompanyOrderByWithRelationInput]) {
  findManyCompany(where: $where, orderBy: $orderBy) {
    ...companyFields
  }
}
    ${CompanyFieldsFragmentDoc}`;

/**
 * __useFindManyCompanyQuery__
 *
 * To run a query within a React component, call `useFindManyCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyCompanyQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useFindManyCompanyQuery(baseOptions?: Apollo.QueryHookOptions<FindManyCompanyQuery, FindManyCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindManyCompanyQuery, FindManyCompanyQueryVariables>(FindManyCompanyDocument, options);
      }
export function useFindManyCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindManyCompanyQuery, FindManyCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindManyCompanyQuery, FindManyCompanyQueryVariables>(FindManyCompanyDocument, options);
        }
export type FindManyCompanyQueryHookResult = ReturnType<typeof useFindManyCompanyQuery>;
export type FindManyCompanyLazyQueryHookResult = ReturnType<typeof useFindManyCompanyLazyQuery>;
export type FindManyCompanyQueryResult = Apollo.QueryResult<FindManyCompanyQuery, FindManyCompanyQueryVariables>;
export const GetCompaniesDropdownOptionsDocument = gql`
    query getCompaniesDropdownOptions($keyword: String) {
  getCompaniesDropdownOptions(keyword: $keyword) {
    ...companyFields
  }
}
    ${CompanyFieldsFragmentDoc}`;

/**
 * __useGetCompaniesDropdownOptionsQuery__
 *
 * To run a query within a React component, call `useGetCompaniesDropdownOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesDropdownOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesDropdownOptionsQuery({
 *   variables: {
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useGetCompaniesDropdownOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetCompaniesDropdownOptionsQuery, GetCompaniesDropdownOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompaniesDropdownOptionsQuery, GetCompaniesDropdownOptionsQueryVariables>(GetCompaniesDropdownOptionsDocument, options);
      }
export function useGetCompaniesDropdownOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompaniesDropdownOptionsQuery, GetCompaniesDropdownOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompaniesDropdownOptionsQuery, GetCompaniesDropdownOptionsQueryVariables>(GetCompaniesDropdownOptionsDocument, options);
        }
export type GetCompaniesDropdownOptionsQueryHookResult = ReturnType<typeof useGetCompaniesDropdownOptionsQuery>;
export type GetCompaniesDropdownOptionsLazyQueryHookResult = ReturnType<typeof useGetCompaniesDropdownOptionsLazyQuery>;
export type GetCompaniesDropdownOptionsQueryResult = Apollo.QueryResult<GetCompaniesDropdownOptionsQuery, GetCompaniesDropdownOptionsQueryVariables>;
export const SearchRelatedCompaniesDocument = gql`
    query searchRelatedCompanies($searchString: String, $model: String) {
  searchRelatedCompanies(searchString: $searchString, model: $model) {
    ...companyFields
  }
}
    ${CompanyFieldsFragmentDoc}`;

/**
 * __useSearchRelatedCompaniesQuery__
 *
 * To run a query within a React component, call `useSearchRelatedCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchRelatedCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchRelatedCompaniesQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *      model: // value for 'model'
 *   },
 * });
 */
export function useSearchRelatedCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<SearchRelatedCompaniesQuery, SearchRelatedCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchRelatedCompaniesQuery, SearchRelatedCompaniesQueryVariables>(SearchRelatedCompaniesDocument, options);
      }
export function useSearchRelatedCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchRelatedCompaniesQuery, SearchRelatedCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchRelatedCompaniesQuery, SearchRelatedCompaniesQueryVariables>(SearchRelatedCompaniesDocument, options);
        }
export type SearchRelatedCompaniesQueryHookResult = ReturnType<typeof useSearchRelatedCompaniesQuery>;
export type SearchRelatedCompaniesLazyQueryHookResult = ReturnType<typeof useSearchRelatedCompaniesLazyQuery>;
export type SearchRelatedCompaniesQueryResult = Apollo.QueryResult<SearchRelatedCompaniesQuery, SearchRelatedCompaniesQueryVariables>;
export const CreateFeedbackDataDocument = gql`
    mutation createFeedbackData($company: String!, $companyDescription: String!, $companyWebsite: String!, $aiRelatedCompanies: [String]!, $aiRelatedCompaniesStockCode: [String]!, $relatedCompanies: [String]!, $relatedCompaniesStockCode: [String]!) {
  createFeedbackData(
    company: $company
    companyDescription: $companyDescription
    companyWebsite: $companyWebsite
    aiRelatedCompanies: $aiRelatedCompanies
    aiRelatedCompaniesStockCode: $aiRelatedCompaniesStockCode
    relatedCompanies: $relatedCompanies
    relatedCompaniesStockCode: $relatedCompaniesStockCode
  ) {
    ...FeedbackDataField
  }
}
    ${FeedbackDataFieldFragmentDoc}`;
export type CreateFeedbackDataMutationFn = Apollo.MutationFunction<CreateFeedbackDataMutation, CreateFeedbackDataMutationVariables>;

/**
 * __useCreateFeedbackDataMutation__
 *
 * To run a mutation, you first call `useCreateFeedbackDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeedbackDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeedbackDataMutation, { data, loading, error }] = useCreateFeedbackDataMutation({
 *   variables: {
 *      company: // value for 'company'
 *      companyDescription: // value for 'companyDescription'
 *      companyWebsite: // value for 'companyWebsite'
 *      aiRelatedCompanies: // value for 'aiRelatedCompanies'
 *      aiRelatedCompaniesStockCode: // value for 'aiRelatedCompaniesStockCode'
 *      relatedCompanies: // value for 'relatedCompanies'
 *      relatedCompaniesStockCode: // value for 'relatedCompaniesStockCode'
 *   },
 * });
 */
export function useCreateFeedbackDataMutation(baseOptions?: Apollo.MutationHookOptions<CreateFeedbackDataMutation, CreateFeedbackDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFeedbackDataMutation, CreateFeedbackDataMutationVariables>(CreateFeedbackDataDocument, options);
      }
export type CreateFeedbackDataMutationHookResult = ReturnType<typeof useCreateFeedbackDataMutation>;
export type CreateFeedbackDataMutationResult = Apollo.MutationResult<CreateFeedbackDataMutation>;
export type CreateFeedbackDataMutationOptions = Apollo.BaseMutationOptions<CreateFeedbackDataMutation, CreateFeedbackDataMutationVariables>;