import React from "react";
import Drawer, { DrawerProps } from "@mui/material/Drawer";
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Toolbar from "@mui/material/Toolbar/Toolbar";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import { navItems } from "./navItems";
import { NavLink } from "react-router-dom";
import clsx from "clsx";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerContainer: {
      overflow: "auto",
    },
    navLink: {
      fontSize: "1rem",
      color: theme.palette.text.primary,
      display: "inline-flex",
      padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(3)}px`,
      textDecoration: "none",
      width: "100%",
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
    },
    activeNavLink: {
      color: theme.palette.primary.main,
      fontWeight: "bold"
    },
  }),
);

const NavDrawer: React.FC<DrawerProps & { setIsOpenDrawer: (value: React.SetStateAction<boolean>) => void }> = (
  props,
) => {
  const { setIsOpenDrawer, ...drawerOnlyProps } = props;
  const classes = useStyles();
  const drawerProps = Object.assign<DrawerProps, DrawerProps>(
    {
      className: classes.drawer,
      variant: "temporary",
      classes: {
        paper: classes.drawerPaper,
      },
    },
    drawerOnlyProps,
  );

  return (
    <Drawer {...drawerProps}>
      <Toolbar />
      <div className={classes.drawerContainer}>
        <List>
          {navItems.map((v, i) => (
            <ListItem disableGutters key={i}>
              <NavLink
                to={v.url}
                key={v.url}
                onClickCapture={() => {
                  setIsOpenDrawer(false);
                }}
                className={({ isActive }) => clsx(classes.navLink, isActive && classes.activeNavLink)}

              >
                {v.name}
              </NavLink>
            </ListItem>
          ))}
        </List>
      </div>
    </Drawer>
  );
};

export { NavDrawer };
